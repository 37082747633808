import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import { AuthContext } from "../context/AuthContext";

export default function Footer() {
  const { bp } = useContext(ThemeContext);
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Box
      sx={{
        minHeight: 75,
        height: "100%",
        width: "100%",

        backgroundColor: "#131f33",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        paddingBlock: 20,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: bp?.md ? "column" : "row",

          gap: 10,
          color: "white",
          width: "100%",
          maxWidth: 1250,
          paddingInline: 20,
        }}
      >
        <p style={{ margin: 0 }}>
          © {new Date()?.getFullYear()}, All Rights Reserved
        </p>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: bp?.md ? "column" : "row",

            gap: 0,
          }}
        >
          <Link to="/tos">
            <FooterButton>Terms of Service</FooterButton>
          </Link>

          <Link to="/privacyService">
            <FooterButton>Privacy service</FooterButton>
          </Link>

          {!isAuthenticated && (
            <>
              <Link to="/login">
                <FooterButton> Login</FooterButton>
              </Link>

              {!bp?.md && (
                <Box
                  sx={{
                    width: "1px",
                    height: 30,
                    backgroundColor: "rgba(255,255,255,.25)",

                    marginInline: 20,
                  }}
                />
              )}

              <Link to="/admin/signup">
                <FooterButton
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",

                    color: "#131f33",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.85)",
                    },
                  }}
                >
                  Sign Up
                </FooterButton>
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const FooterButton = ({ sx, children }) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        color: "white",
        borderRadius: 5,
        translate: "all 0.3s ease-in-out",
        opacity: 0.85,
        "&:hover": {
          opacity: 1,
        },

        paddingInline: 20,

        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

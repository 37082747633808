import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "./SnackbarContext";
import httpClient from "../libs/httpClient";

import Loading from "../components/LoadingPage";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const snackbarContext = useContext(SnackbarContext);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPass, setCurrentPass] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userId, setUserId] = useState();
  const [user, setUser] = useState();

  const [role, setRole] = useState();
  const [token, setToken] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    toggleAuth();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleAuth = async () => {
    setAuthLoaded(true);
    if (
      (await localStorage.getItem("accessToken")) &&
      (await localStorage.getItem("id"))
    ) {
      const _id = await localStorage.getItem("id");

      httpClient()
        .get(`/users/${_id}`)
        .then((res) => {
          console.log(res.data);
          saveUserData(res.data);
        })
        .catch((err) => {
          console.log("ERROR from ToggleAuth : ", err.message);
          logout();
          setIsAuthenticated(false);
        });
    } else {
      setIsAuthenticated(false);
      setAuthLoaded(true);
    }
  };

  const saveUserData = async (user) => {
    setLoading(true);
    try {
      setUserId(user._id);
      setUser(user);
      setRole(user.role);
      setToken(user.accessToken);
      setIsAuthenticated(true);

      user.accessToken &&
        (await localStorage.setItem("accessToken", user.accessToken));
      await localStorage.setItem("id", user._id || user.id);
      await localStorage.setItem("user", JSON.stringify(user));
    } catch (err) {
      console.log(err);
      navigate("/login");
    }
    setAuthLoaded(true);
    setLoading(false);
  };

  const loginWithEmail = (email, password, token) => {
    setLoading(true);
    httpClient()
      .post("/auth/login", { email, password, token })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          return res.data;
        } else {
          console.log(res?.data?.message);
          snackbarContext.message(res?.data?.message, "error");
          setLoading(false);
          return null;
        }
      })
      .then((user) => {
        // if (!["user", "admin"].includes(user?.role)) {
        //   throw new Error("Unauthorized");
        // }
        // Please remove this 2 lines of code before production
        // saveUserData(user);
        // setLoading(false);
        // Please remove above 2 lines of code before production

        if (user.success) {
          snackbarContext.message(`Please check your email.`, "info");
          setCurrentPass(true);
        } else {
          setLoading(false);
          snackbarContext.message(
            "Unauthorized! Try again with correct credentials.",
            "error"
          );

          throw new Error(user?.status || "Unauthorized");
        }

        // navigate("/admin");
      })
      .catch((err) => {
        // console.log(err?.message);
        setLoading(false);

        snackbarContext.message(
          err?.message || "You have entered an invalid email or password",
          "error"
        );
      });
  };

  const loginWithCode = (confirmationCode) => {
    setLoading(true);
    httpClient()
      .get(`/auth/confirm/${confirmationCode}`)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          console.log(res?.data?.message);
          snackbarContext.message(res?.data?.message, "error");
          setLoading(false);
          return null;
        }
      })
      .then((user) => {
        setCurrentPass(true);
        if (!["user", "admin"].includes(user?.role)) {
          throw new Error("Unauthorized");
        }
        saveUserData(user);
        setLoading(false);
        snackbarContext.message(
          `Welcome, ${user?.fullName || user?.name || user?.email}`,
          "info"
        );
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        navigate("/login");

        snackbarContext.message(
          "You have entered an invalid email or password",
          "error"
        );
      });
  };

  const signInWithEmail = async (fullName, role, email, password) => {
    setLoading(true);
    httpClient()
      .post("/auth/signup", { fullName, role, email, password })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setLoading(false);
          return res.data;
        } else {
          console.log(res?.data?.message?.replace("undefined - ", ""));
          snackbarContext.message(
            res?.data?.message?.replace("undefined - ", ""),
            "error"
          );
          setLoading(false);
          return null;
        }
      })
      .then((user) => {
        // if (!["user", "admin"].includes(user?.role)) {
        //   throw new Error("Unauthorized");
        // }
        // Please remove this 2 lines of code before production
        // saveUserData(user);
        // setLoading(false);
        // Please remove above 2 lines of code before production

        console.log("===== > ", user);

        if (user.success) {
          snackbarContext.message(`Please check your email.`, "info");
          setCurrentPass(true);
          setLoading(false);
        } else {
          setLoading(false);
          snackbarContext.message(
            user?.status || "Unauthorized! Try again with correct credentials.",
            "error"
          );

          throw new Error(user?.status || "Unauthorized");
        }

        // navigate("/admin");
      })
      .catch((err) => {
        console.log("xxx ====", err?.message);
        setLoading(false);

        snackbarContext.message(
          err?.message || "You have entered an invalid email or password",
          "error"
        );
      });
    // .then((res) => res.data)
    // .then((user) => {
    //   console.log(user);

    //   saveUserData(user);
    //   setLoading(false);
    //   snackbarContext.message(
    //     `Welcome, ${user?.fullName || user?.email}`,
    //     "info"
    //   );
    //   navigate("/admin");
    // })
    // .catch((err) => {
    //   console.log(err);
    //   setLoading(false);
    //   snackbarContext.message(
    //     "You have entered an invalid email or password",
    //     "error"
    //   );
    // });
  };

  const requestPasswordReset = async (email) => {
    setLoading(true);
    httpClient()
      .post("/auth/forgot-password", { email })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          console.log(res?.data?.message);
          snackbarContext.message(res?.data?.message, "error");
          setLoading(false);
          return null;
        }
      })
      .then((user) => {
        setLoading(false);
        snackbarContext.message(
          `Please check your email for the reset link.`,
          "info"
        );
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        snackbarContext.message(
          "You have entered an invalid email or password",
          "error"
        );
      });
  };

  const logout = async () => {
    setLoading(true);

    httpClient()
      .post(`/users/logout`, { userId, token })
      .then(async (res) => {
        await localStorage.clear();

        setIsAuthenticated(false);

        setUserId();
        setRole();
        setUser();
        setToken();
        navigate("/");
      })
      .catch(async (err) => {
        await localStorage.clear();

        setIsAuthenticated(false);

        setUserId();
        setRole();
        setUser();
        setToken();
        navigate("/");
      });

    setLoading(false);
  };

  const value = {
    authLoaded,
    loading,
    isAuthenticated,
    user,
    userId,
    role,
    token,
    loginWithEmail,
    signInWithEmail,
    requestPasswordReset,
    logout,
    toggleAuth,
    currentPass,
    loginWithCode,
  };
  return (
    <AuthContext.Provider value={value}>
      {authLoaded === false ? <Loading fullSize={true} /> : props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContextProvider;

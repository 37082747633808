import { Box } from "@mui/material";
import React, { useContext } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ThemeContext } from "../context/ThemeContext";

export default function PrivacyServicePage() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        position: "relative",
        background: "#F5F7F9",
        minHeight: 700,
        // height: "100svh",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />

      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,
          paddingBlock: 40,

          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 30,
        }}
      >
        <Box sx={{ width: "100%", marginBlock: 20 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",

              marginBottom: 40,
            }}
          >
            <h1 style={{ color: theme.textColor }}>Privacy service</h1>
          </Box>
          <p style={{ color: theme.textColor2, fontSize: ".9rem" }}>
            Last Updated: 10/10/2023
          </p>
        </Box>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            1. Introduction
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil is committed to protecting the privacy and security of your
            personal information. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your data when you use our QR
            code-based feedback service. By using our service, you consent to
            the practices described in this Privacy Policy.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            2. Information We Collect
          </h3>
          <p style={{ color: theme.textColor2 }}>
            <ul>
              <li>
                2.1. User-Provided Information: When you sign up for Privil, we
                may collect information such as your name, email address,
                restaurant name, and payment details for subscription purposes.
              </li>

              <li>
                2.2. Feedback Data: When restaurant patrons provide feedback
                through our service, we collect and store the feedback content,
                including ratings and comments.
              </li>

              <li>
                2.3. Log Data: We collect information about your usage of our
                service, including your IP address, device information, browser
                type, and access times.
              </li>
            </ul>
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            3. How We Use Your Information
          </h3>
          <p style={{ color: theme.textColor2 }}>
            <ul>
              <li>
                3.1. Provide and Improve our Service: We use your information to
                provide, maintain, and improve our feedback service, including
                customer support and system updates.
              </li>

              <li>
                3.2. Communication: We may use your email address to send you
                important service-related communications, such as subscription
                renewals and feedback notifications.
              </li>

              <li>
                3.3. Aggregate Data: We may use aggregated and anonymized data
                for statistical and research purposes.
              </li>
            </ul>
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            4. Data Sharing
          </h3>
          <p style={{ color: theme.textColor2 }}>
            We do not sell, trade, or rent your personal information to third
            parties. However, we may share your information with the following
            entities:
            <ul>
              <li>
                4.1. Service Providers: We may share your data with third-party
                service providers who assist us in delivering our services.
                These providers are bound by contractual obligations to keep
                your information confidential and secure.
              </li>

              <li>
                4.2. Legal Compliance: We may disclose your information if
                required by law or in response to a valid legal request.
              </li>
            </ul>
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            5. Data Security
          </h3>
          <p style={{ color: theme.textColor2 }}>
            We implement industry-standard security measures to protect your
            data from unauthorized access, alteration, disclosure, or
            destruction. However, no method of data transmission or storage is
            100% secure, and we cannot guarantee absolute security.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            6. Your Choices
          </h3>
          <p style={{ color: theme.textColor2 }}>
            You may access, update, or delete your personal information by
            logging into your account. You can also contact us at [Contact
            Email] for assistance.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            7. Children's Privacy
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Our service is not intended for children under the age of 13. We do
            not knowingly collect personal information from children under 13.
            If you believe we have inadvertently collected information from a
            child under 13, please contact us, and we will promptly delete it.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            8. Changes to this Privacy Policy
          </h3>
          <p style={{ color: theme.textColor2 }}>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for legal reasons. The updated Privacy
            Policy will be posted on our website, and your continued use of our
            service after such changes constitute acceptance of the updated
            policy.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            9. Limitation of Liability
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil is not responsible for any damages, losses, or liabilities
            arising from the use of our service, including but not limited to,
            the accuracy or quality of feedback provided.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            10. Changes to Terms of Service
          </h3>
          <p style={{ color: theme.textColor2 }}>
            We may update these Terms of Service from time to time. Any changes
            will be posted on our website, and your continued use of our service
            after the changes constitute acceptance of the updated terms.
          </p>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

import { Box, ButtonBase } from "@mui/material";
import React, { useContext } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { AuthContext } from "../../context/AuthContext";

const options = { year: "numeric", month: "long", day: "numeric" };

export default function SubscriptionSuccessPage() {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  return (
    <Box
      sx={{
        position: "relative",
        background: "linear-gradient(90deg, #213659 0%, #131f33 100%)",
        minHeight: 700,
        height: "100svh",
        // height: "100%",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 450,
          height: "100%",
          maxHeight: 600,

          paddingInline: 20,
          paddingBlock: 40,

          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",

          gap: 20,
          borderRadius: 16,
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            height: 100,
            width: 100,
            // backgroundColor: "rgba(0,0,0,0.05)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckRoundedIcon
            sx={{
              color: "#00B341",
              fontSize: "4rem",
            }}
          />
        </Box>

        <h1
          style={{
            color: theme.textColor,
          }}
        >
          Subscription Activated Successfully!
        </h1>
        <p
          style={{
            color: theme.textColor2,
          }}
        >
          {`Thank you for subscribing to our service. Your subscription is now
          active.`}
          {/* {`Thank you for subscribing to our service. Your subscription is now
          active and will expire on ${new Date(
            user?.subscription?.endDate
          )?.toLocaleDateString("en-US", options)}.`} */}
        </p>

        <Link
          to="/admin"
          style={{
            marginTop: 20,
            textDecoration: "none",
          }}
        >
          <ButtonBase
            sx={{
              background:
                theme.name === "dark"
                  ? "#F5F7F9"
                  : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
              color: theme.name === "dark" ? "#131f33" : "#fff",
              borderRadius: 12,
              fontSize: 16,
              fontWeight: 600,

              padding: "16px 24px",

              "&:hover": {
                background:
                  theme.name === "dark"
                    ? "#F5F7F9"
                    : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                color: theme.name === "dark" ? "#131f33" : "#fff",

                opacity: 0.85,
              },
            }}
          >
            Go to Homepage
          </ButtonBase>
        </Link>
      </Box>
    </Box>
  );
}

import { Box } from "@mui/material";
import React, { useContext } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ThemeContext } from "../context/ThemeContext";

export default function TermsOfServicePage() {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        position: "relative",
        background: "#F5F7F9",
        minHeight: 700,
        // height: "100svh",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Header />

      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,
          paddingBlock: 40,

          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 30,
        }}
      >
        <Box sx={{ width: "100%", marginBlock: 20 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",

              marginBottom: 40,
            }}
          >
            <h1 style={{ color: theme.textColor }}>Terms of Service (ToS)</h1>
          </Box>
          <p style={{ color: theme.textColor2, fontSize: ".9rem" }}>
            Last Updated: 10/10/2023
          </p>
        </Box>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            1. Acceptance of Terms
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Welcome to Privil! By accessing or using our services, you agree to
            be bound by these Terms of Service. If you do not agree to these
            terms, please do not use our services.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            2. Description of Service
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil provides a subscription-based QR code-based feedback service
            for restaurant owners. Our service allows restaurant patrons to
            provide anonymous feedback through QR code scanning, and restaurant
            owners and managers to receive feedback via email.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            3. Subscription Plans
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil offers subscription plans, which covers access to our
            feedback service. Payment is required to activate your subscription
            and must be made in advance.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            4. Privacy
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Your privacy is important to us. Please review our Privacy Policy
            [link to Privacy Policy] to understand how we collect, use, and
            protect your information.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            5. User Conduct
          </h3>
          <p style={{ color: theme.textColor2 }}>
            By using our service, you agree not to: Use our service for any
            illegal or unauthorized purpose. Harass, threaten, or harm other
            users. Attempt to gain unauthorized access to our systems or data.
            Interfere with the proper functioning of our service. Violate any
            applicable laws or regulations.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            6. Feedback Content
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Feedback provided through our service should be respectful and
            constructive. Privil reserves the right to remove or refuse any
            feedback that is inappropriate, offensive, or violates our
            guidelines.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            7. Intellectual Property
          </h3>
          <p style={{ color: theme.textColor2 }}>
            All content and materials on our website and within our service are
            owned or licensed by Privil and are protected by copyright and other
            intellectual property laws.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            8. Termination
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil reserves the right to terminate or suspend your access to our
            service at any time, without notice, for any reason, including a
            breach of these Terms of Service.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            9. Limitation of Liability
          </h3>
          <p style={{ color: theme.textColor2 }}>
            Privil is not responsible for any damages, losses, or liabilities
            arising from the use of our service, including but not limited to,
            the accuracy or quality of feedback provided.
          </p>
        </Box>

        <Box>
          <h3
            style={{
              color: theme.textColor,
              marginBottom: 10,
            }}
          >
            10. Changes to Terms of Service
          </h3>
          <p style={{ color: theme.textColor2 }}>
            We may update these Terms of Service from time to time. Any changes
            will be posted on our website, and your continued use of our service
            after the changes constitute acceptance of the updated terms.
          </p>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

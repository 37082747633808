import { useContext, useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar, Box, Chip, Switch, IconButton, Tooltip } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import Header from "../../components/Header";
import { ThemeContext } from "../../context/ThemeContext";
import httpClient from "../../libs/httpClient";
import moment from "moment";
import ManageSubscribeDialog from "../../dialog/ManageSubscribeDialog";
import capitalizeFirstLetter from "../../libs/capitalizeFirstLetter";
import { SnackbarContext } from "../../context/SnackbarContext";

export default function AdminUsersPage() {
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = async () => {
    httpClient()
      .get("/users")
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        setUsers(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <div className="qrCodeGenPageContainer">
        <div
          className="qrCodeGenPage"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <h1>Users</h1>
          </div>

          <TableContainer
            sx={{
              marginTop: 20,

              marginBottom: 20,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
              borderRadius: theme.borderRadius,
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Roll</TableCell>
                  <TableCell align="center">Is Active</TableCell>
                  <TableCell align="center">Is Subscribed</TableCell>

                  <TableCell align="center">Subscribe At</TableCell>
                  <TableCell align="center">Expire At</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody sx={{ minHeight: 250 }}>
                {!loading &&
                  users.map((row) => (
                    <UserItem key={row._id} row={row} getData={getData} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {loading && (
            <Box
              sx={{
                backgroundColor: theme === "dark" ? "#333" : "#fff",
                borderRadius: theme.borderRadius,

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 250,
                height: "100%",
              }}
            >
              <p>Loading data...</p>
            </Box>
          )}
        </div>
      </div>
    </>
  );
}

const UserItem = ({ row, getData }) => {
  const snackbarContext = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [showManageSubscribeDialog, setShowManageSubscribeDialog] =
    useState(false);

  const [isActive, setIsActive] = useState(row.isActive);
  const [role, setRole] = useState(row.role);

  // Red - No subscription
  // Blue -
  //

  //   const xhandleChange = (event) => {
  //     setIsActive(event.target.checked);
  //     console.log(event.target.checked);

  //     httpClient()
  //       .put(`/users/${row._id}`, {
  //         isActive: event.target.checked,
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         setIsActive(res.data.isActive);
  //       })
  //       .catch((err) => {
  //         setIsActive(row.isActive);
  //         console.log(err);
  //       });
  //   };

  const handleChange = (e) => {
    setLoading(true);
    console.log(e);

    if (e?.hasOwnProperty("isActive")) {
      setIsActive(e?.isActive);
    }
    if (e?.hasOwnProperty("role")) {
      setRole(e?.role);
    }

    console.log(e);

    httpClient()
      .put(`/users/${row._id}`, e)
      .then((res) => {
        console.log(res.data);
        snackbarContext.message(`Products updated successfully`, "success");

        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        snackbarContext.message(
          err?.response?.data?.message || `Faild to update product`,
          "error"
        );

        if (e?.hasOwnProperty("isActive")) {
          setIsActive(row?.isActive);
        }
        if (e?.hasOwnProperty("role")) {
          setRole(row?.role);
        }

        getData();
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    httpClient()
      .delete(`/users/${row?._id}`)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        getData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return loading ? (
    <TableRow
      sx={{ height: 75, "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
      <TableCell component="th" scope="row" />
    </TableRow>
  ) : (
    <>
      <TableRow
        sx={{ height: 75, "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Avatar
              src={`https://source.boringavatars.com/beam/120/${row.fullName}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`}
            />

            <div>
              <h4>{row.fullName}</h4>
              <p>{row.email}</p>
            </div>
          </Box>
        </TableCell>

        <TableCell align="center">
          <Chip
            onClick={() =>
              handleChange({ role: role === "user" ? "admin" : "user" })
            }
            label={capitalizeFirstLetter(row.role)}
          />
        </TableCell>

        <TableCell align="center">
          <Switch
            checked={isActive}
            onChange={(e) => handleChange({ isActive: e.target.checked })}
          />
        </TableCell>

        <TableCell align="center">
          <Tooltip
            title={
              row?.subscription?.isFreebie || row?.isFreebie
                ? "Freebie"
                : capitalizeFirstLetter(row?.subscription?.status) || "Inactive"
            }
          >
            <IconButton
              sx={{
                color:
                  row?.subscription?.isFreebie || row?.isFreebie
                    ? "#0d6efd"
                    : ["active", "trialing"].includes(row?.subscription?.status)
                    ? "green"
                    : "red",
                backgroundColor:
                  row?.subscription?.isFreebie || row?.isFreebie
                    ? "#0d6efd1f"
                    : ["active", "trialing"].includes(row?.subscription?.status)
                    ? "#00ff001f"
                    : "#ff00001f",
              }}
              onClick={() => setShowManageSubscribeDialog((e) => !e)}
            >
              <PaidIcon />
            </IconButton>
          </Tooltip>
        </TableCell>

        <TableCell align="center">
          {["active", "trialing"].includes(row?.subscription?.status) ? (
            <Tooltip
              title={moment(row?.subscription?.currentPeriodStart).format(
                "MMMM DD, YYYY"
              )}
            >
              {moment(row?.subscription?.currentPeriodStart).fromNow()}
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell align="center">
          {["active", "trialing"].includes(row?.subscription?.status) ? (
            <Tooltip
              title={moment(row?.subscription?.nextBillingDate).format(
                "MMMM DD, YYYY"
              )}
            >
              {moment(row?.subscription?.nextBillingDate).fromNow()}
            </Tooltip>
          ) : (
            "-"
          )}
        </TableCell>

        <TableCell align="right">
          <a href={`mailto:${row?.email}`}>
            <AlternateEmailRoundedIcon />
          </a>
        </TableCell>

        <TableCell align="right">
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete} sx={{ color: "red" }}>
              <DeleteRoundedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ManageSubscribeDialog
        open={showManageSubscribeDialog}
        setOpen={setShowManageSubscribeDialog}
        user={row}
        getData={getData}
      />
    </>
  );
};

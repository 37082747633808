import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
// import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import logo from "../assets/logo.svg";
import logo_black_text from "../assets/logo_text_black.svg";
import logo_text from "../assets/logo_text.svg";

import { ThemeContext } from "../context/ThemeContext";
import { AuthContext } from "../context/AuthContext";

export default function Header() {
  const { theme, bp, showAppDrawer, setShowAppDrawer } =
    useContext(ThemeContext);
  const { isAuthenticated, logout, user } = useContext(AuthContext);

  return (
    <div
      style={{
        width: "100%",
        height: 60,
        display: "flex",
        justifyContent: "center",
      }}
      className="no-print"
    >
      <div
        style={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {/* <Link to="/admin"> */}
        <Link to="/">
          <div
            style={{
              display: "flex",
              gap: 20,
              alignItems: "center",
            }}
          >
            <img style={{ height: 40 }} src={logo} alt="Logo Img" />
            <img
              style={{ height: 20 }}
              src={theme.name === "light" ? logo_black_text : logo_text}
              alt="Logo Text Img"
            />
          </div>
        </Link>

        {bp?.md ? (
          <div style={{ display: "flex", gap: 20 }}>
            <IconButton onClick={() => setShowAppDrawer((e) => !e)}>
              <MenuRoundedIcon />
            </IconButton>

            <Drawer
              anchor="left"
              open={showAppDrawer}
              onClose={(e) => setShowAppDrawer(false)}
              sx={{
                // width: "100%",
                // maxWidth: 250,

                "& .MuiDrawer-paper": {
                  backgroundColor: theme.name === "dark" ? "#131f33" : "#fff",
                  color: theme.name === "dark" ? "#fff" : "#131f33",
                },
              }}
            >
              <Box
                sx={{
                  width: 300,

                  "& a": {
                    width: "100%",
                  },
                  "& button": {
                    width: "100%",
                  },

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20,

                  padding: 20,
                }}
              >
                <Link
                  onClick={() => setShowAppDrawer(false)}
                  to="/"
                  style={{ marginBlock: 20 }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      gap: 20,
                    }}
                  >
                    <img style={{ height: 30 }} src={logo} alt="Logo Img" />
                    <img
                      style={{
                        height: 15,
                        objectFit: "contain",
                      }}
                      src={theme.name === "light" ? logo_black_text : logo_text}
                      alt="Logo Text Img"
                    />
                  </div>
                </Link>
                <NavLink onClick={() => setShowAppDrawer(false)} to="/">
                  Home
                </NavLink>

                {!["active", "trialing"].includes(
                  user?.subscription?.status
                ) && (
                  <NavLink
                    onClick={() => setShowAppDrawer(false)}
                    href="/#pricing"
                  >
                    Pricing
                  </NavLink>
                )}
                {/* <NavLink to="/about">About Us</NavLink> */}
                <NavLink
                  onClick={() => setShowAppDrawer(false)}
                  href="/#contact"
                >
                  Contact
                </NavLink>
                <Divider
                  orientation="horizontal"
                  sx={{
                    width: "100%",
                    backgroundColor: theme.textColor3,
                    opacity: 0.15,
                  }}
                />
                {isAuthenticated ? (
                  <ProfileMenu
                    logout={logout}
                    setShowAppDrawer={setShowAppDrawer}
                  />
                ) : (
                  <>
                    <NavLink
                      onClick={() => setShowAppDrawer(false)}
                      to="/login"
                    >
                      Login
                    </NavLink>

                    <NavLink
                      onClick={() => setShowAppDrawer(false)}
                      // to="/signup"
                      href="/#pricing"
                      sx={{
                        background:
                          theme.name === "dark"
                            ? "#F5F7F9"
                            : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                        color: theme.name === "dark" ? "#131f33" : "#fff",

                        borderRadius: "5px !important",

                        translate: "all 0.3s ease-in-out",
                        opacity: 1,
                        "&:hover": {
                          background:
                            theme.name === "dark"
                              ? "#F5F7F9"
                              : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                          color: theme.name === "dark" ? "#131f33" : "#fff",
                          opacity: 0.85,
                        },
                      }}
                    >
                      Sign Up
                    </NavLink>
                  </>
                )}
              </Box>
            </Drawer>
          </div>
        ) : (
          <div
            style={{
              gap: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <NavLink to="/admin">Admin</NavLink> */}

            <NavLink to="/">Home</NavLink>

            {!["active", "trialing"].includes(user?.subscription?.status) && (
              <NavLink href="/#pricing">Pricing</NavLink>
            )}

            {/* <NavLink to="/about">About Us</NavLink> */}
            <NavLink href="/#contact">Contact</NavLink>

            {isAuthenticated ? (
              <ProfileMenu
                logout={logout}
                setShowAppDrawer={setShowAppDrawer}
              />
            ) : (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    // height: 30,
                    backgroundColor: theme.textColor3,
                    opacity: 0.15,
                  }}
                />

                <NavLink to="/login">Login</NavLink>

                <NavLink
                  // to="/admin/signup"
                  href="/#pricing"
                  sx={{
                    // backgroundColor: theme.buttonTextColor,
                    background:
                      theme.name === "dark"
                        ? "#F5F7F9"
                        : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                    color: theme.name === "dark" ? "#131f33" : "#fff",
                    // color: theme.buttonColor,

                    paddingInline: 20,
                    borderRadius: 5,
                    translate: "all 0.3s ease-in-out",
                    opacity: 1,
                    "&:hover": {
                      background:
                        theme.name === "dark"
                          ? "#F5F7F9"
                          : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                      color: theme.name === "dark" ? "#131f33" : "#fff",
                      opacity: 0.85,
                    },
                  }}
                >
                  Sign Up
                </NavLink>
              </>
            )}
            {/* <Button sx={{ textTransform: "none" }}>Admin</Button> */}
          </div>
        )}
      </div>
    </div>
  );
}

const ProfileMenu = ({ logout, setShowAppDrawer }) => {
  const { user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{ maxWidth: 40 }}
        onClick={handleClick}
      >
        <Avatar
          src={`https://source.boringavatars.com/beam/120/${user.fullName}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`}
        />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{
            width: "100%",
          }}
          component={Link}
          to={user?.role === "admin" ? "/admin" : "/dashboard"}
          onClick={() => {
            handleClose();
            setShowAppDrawer(false);
          }}
        >
          <ListItemIcon>
            <DashboardRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </MenuItem>

        <MenuItem
          sx={{
            width: "100%",
          }}
          component={Link}
          to="/settings"
          onClick={() => {
            handleClose();
            setShowAppDrawer(false);
          }}
        >
          <ListItemIcon>
            <SettingsRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>

        <Divider
          sx={{
            paddingTop: 10,
          }}
        />

        <MenuItem
          sx={{
            width: "100%",
          }}
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const NavLink = ({ children, to, href, sx, onClick }) => {
  const { theme } = useContext(ThemeContext);

  const BTN = () => {
    return (
      <Button
        sx={{
          fontWeight: 500,
          fontSize: "1rem",

          textTransform: "none",
          // color: theme.buttonTextColor,
          color: theme.textColor,
          borderRadius: 5,
          translate: "all 0.3s ease-in-out",
          opacity: 0.85,
          "&:hover": {
            opacity: 1,
          },

          ...sx,
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  };

  return href ? (
    <a href={href}>
      <BTN />
    </a>
  ) : (
    <Link to={to} style={{ textDecoration: "none" }}>
      <BTN />
    </Link>
  );
};

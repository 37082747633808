import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Box, Typography, ButtonBase, Button } from "@mui/material";
import { ThemeContext } from "../context/ThemeContext";
import moment from "moment";
import convertToTitleCase from "../libs/convertToTitleCase";
import capitalizeFirstLetter from "../libs/capitalizeFirstLetter";

import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { SnackbarContext } from "../context/SnackbarContext";

export default function AddPaymentMethodDialog({ open, setOpen }) {
  const { message } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);

  // const stripe = useStripe();
  // const elements = useElements();
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(false);

  // console.log("Stripe:", stripe, elements);

  const addPaymentMethod = async () => {
    // setLoading(true);
    // setLoading(true);
    // const cardElement = elements.getElement(CardElement);
    // // console.log("Card Element:", CardElement);
    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    // });
    // // console.log("Payment Method:", paymentMethod, error);
    // console.log("Payment Method:", error);
    // if (error) {
    //   console.log("Error:", error.message);
    //   message(error.message, "error");
    //   setError(error.message);
    //   setLoading(false);
    // } else {
    //   console.log("Payment Method:", paymentMethod);
    //   setOpen(false);
    //   // Handle server-side integration here
    //   setLoading(false);
    // }
  };

  return (
    <Dialog
      onClose={() => setOpen((e) => !e)}
      open={open}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        ".MuiPaper-root": { borderRadius: 8 },
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle sx={{ padding: "20px 20px 0 20px" }}>
        Add Payment Method
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: 20,
        }}
      >
        {/* <CardElement /> */}
      </DialogContent>

      <DialogActions
        sx={{
          gap: 20,
          padding: 20,

          display: "flex",
        }}
      >
        <Button
          sx={{
            color: "#000000",
            backgroundColor: "#00000011",
            padding: "6px 16px",

            textTransform: "none",
            fontWeight: 500,
            borderRadius: 6,
            border: `1px solid #00000033`,
          }}
          onClick={() => setOpen(false)}
        >
          Back
        </Button>

        <Button
          sx={{
            color: "#ffffff",
            backgroundColor: "#000000",
            padding: "6px 16px",

            textTransform: "none",
            fontWeight: 500,
            borderRadius: 6,

            "&:hover": {
              backgroundColor: "#000000cc",
            },
          }}
          onClick={() => addPaymentMethod()}
        >
          Add Payment Method
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { Box } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { AuthContext } from "../../../context/AuthContext";
import Avatar from "@mui/material/Avatar";

export default function ProfileInformationStatus() {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  return (
    <Box
      sx={{
        border: `1px solid ${theme.textColor3 + 66}`,
        padding: "12px 16px",
        borderRadius: 8,

        backgroundColor: theme.ui,

        display: "flex",
        flexDirection: "column",
        gap: 16,
      }}
    >
      <h2 style={{ color: theme.textColor2, fontWeight: 600 }}>
        Profile Information
      </h2>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Box
            sx={{
              height: 50,
              width: 50,
              borderRadius: 25,
              backgroundColor: theme.textColor3 + 33,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              src={`https://source.boringavatars.com/beam/120/${user.fullName}?colors=264653,2a9d8f,e9c46a,f4a261,e76f51`}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <h4 style={{ color: theme.textColor, fontWeight: 500 }}>
              {user?.fullName}
            </h4>
            <h5 style={{ color: theme.textColor2, fontWeight: 400 }}>
              {user?.email}
            </h5>
          </Box>
        </Box>

        {/*  */}
        <h4
          style={{
            color: !user?.isActive ? "red" : "#2E5BFF",
            backgroundColor: !user?.isActive ? "#ff000022" : "#2E5BFF33",

            fontWeight: 500,
            padding: "4px 16px",
            borderRadius: 16,
          }}
        >
          Verified
        </h4>
      </Box>
    </Box>
  );
}

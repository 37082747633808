import { Button } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

import QrCodeIcon from "@mui/icons-material/QrCode";
import ContactsIcon from "@mui/icons-material/Contacts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import { ThemeContext } from "../../context/ThemeContext";

export default function AdminPage() {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Header />

      <div className="qrCodeGenPageContainer">
        <div
          className="qrCodeGenPage"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <h1>Admin</h1>
          </div>

          <div
            style={{
              display: "grid",
              width: "100%",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 20,
              marginTop: 20,
              marginBottom: 20,
              height: "100%",
            }}
          >
            <AdminButton
              icon={<QrCodeIcon style={{ fontSize: "5rem" }} />}
              text="QR Codes"
              link="/admin/qrCodes"
            />
            <AdminButton
              icon={<ContactsIcon style={{ fontSize: "5rem" }} />}
              text="Contact Messages"
              link="/admin/contact"
            />
            <AdminButton
              icon={<PeopleAltIcon style={{ fontSize: "5rem" }} />}
              text="Users"
              link="/admin/users"
            />
          </div>
        </div>
      </div>
    </>
  );
}

const AdminButton = ({ icon, text, link }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Link
      to={link}
      style={{
        textDecoration: "none",
      }}
    >
      <Button
        style={{
          width: "100%",
          textTransform: "none",
          fontSize: "1.25rem",
          padding: "2rem 1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",

          backgroundColor: theme.ui,
          color: theme.textColor,

          borderRadius: theme.borderRadius,
        }}
      >
        {icon}
        {text}
      </Button>
    </Link>
  );
};

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

import Lottie from "react-lottie";
import * as animationData from "../assets/success_anime.json";

export default function FeedbackSubmitedDialog({ open, setOpen }) {
  const { theme } = useContext(ThemeContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  //   const handleClose = () => {
  //     window.opener = null;
  //     window.open("", "_self");
  //     window.close();
  //   };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      //   onClose={() => setOpen((e) => !e)}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: theme.ui,
          borderRadius: theme.borderRadius,
          width: 350,
        },
      }}
    >
      <DialogTitle
        style={{
          paddingLeft: "20px",
          color: theme.textColor,
          textAlign: "center",
        }}
      >
        Thank you!
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "20px 20px 0px 20px",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          position: "relative",
        }}
      >
        {/* <p style={{ color: theme.textColor }}>xD</p> */}

        <Lottie
          options={defaultOptions}
          //   height={400}
          width={200}
          //   isFinished={setHasFinished}
          //   isStopped={setHasFinished}
          //   isPaused={setHasFinished}
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            width: "calc(100% - 40px)",
            height: "calc(100% - 20px)",
            paddingBottom: 20,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center", color: theme.textColor }}>
            Thank you for your feedback, <br />
            We really appreciate your time!
          </p>
        </div>
      </DialogContent>

      {/* <DialogActions
        sx={{
          gap: 20,
          padding: 20,
        }}
      >
        <CustomButton
          onClick={() => handleClose()}
          label={"Click here to close window"}
        />
      </DialogActions> */}
    </Dialog>
  );
}

import React, { useContext, useState } from "react";
import { Box, ButtonBase, Fade } from "@mui/material";
import ReactPlayer from "react-player";
import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const heroImage = "https://s3.us-west-2.amazonaws.com/cdn.privil.link/0.jpg";
const heroVideo =
  "https://s3.us-west-2.amazonaws.com/cdn.privil.link/privil_video.webm";

export default function TopSection() {
  const { user } = useContext(AuthContext);
  const { theme, bp } = useContext(ThemeContext);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 1250,
        height: "100%",

        paddingInline: 20,

        display: "flex",
        flex: 1,
        flexDirection: bp.sm ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 20,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          display: "flex",
          flexDirection: "column",
          gap: 20,
          py: 200,
        }}
      >
        <h1
          style={{
            fontSize: bp?.md ? 42 : 52,
            color: theme.textColor,
            fontWeight: 500,
            textAlign: bp?.md ? "center" : "left",
          }}
        >
          Real-Time Feedback for Restaurant
        </h1>

        <h5
          style={{
            fontSize: bp?.md ? 18 : 22,
            lineHeight: 1.25,
            fontWeight: 500,
            color: theme.textColor2,
            textAlign: bp?.md ? "center" : "left",
          }}
        >
          Empower Your Restaurant with Real-Time Feedback
        </h5>

        <ButtonBase
          component="a"
          href={user ? "/admin" : "/#pricing"}
          sx={{
            marginTop: 20,
            background:
              theme.name === "dark"
                ? "#F5F7F9"
                : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
            color: theme.name === "dark" ? "#131f33" : "#fff",
            borderRadius: 30,
            height: 55,
            fontSize: 16,
            fontWeight: 600,
            "&:hover": {
              background:
                theme.name === "dark"
                  ? "#F5F7F9"
                  : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
              color: theme.name === "dark" ? "#131f33" : "#fff",

              opacity: 0.85,
            },
          }}
        >
          GET STARTED
        </ButtonBase>
      </Box>

      {/* {!bp?.md && ( */}
      <Box
        sx={{
          borderRadius: 50,
          position: "relative",
          overflow: "hidden",
          my: bp?.sm ? 0 : 50,
        }}
      >
        <Fade in={!isVideoPlaying} mountOnEnter unmountOnExit>
          <img
            style={{ position: "absolute", maxWidth: 400 }}
            src={heroImage}
            alt="heroImage"
          />
        </Fade>

        <ReactPlayer
          url={heroVideo}
          loop={true}
          playing={true}
          controls={false}
          muted={true}
          playsinline={true}
          width={400}
          height={711}
          onReady={() => setIsVideoPlaying(true)}
          onStart={() => setIsVideoPlaying(true)}
          onError={() => setIsVideoPlaying(false)}
        />
      </Box>
      {/* )} */}
    </Box>
  );
}

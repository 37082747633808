import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { Box, Typography, ButtonBase, Button } from "@mui/material";
import { ThemeContext } from "../context/ThemeContext";
import moment from "moment";
import convertToTitleCase from "../libs/convertToTitleCase";
import capitalizeFirstLetter from "../libs/capitalizeFirstLetter";
import httpClient from "../libs/httpClient";

export default function ManageSubscribeDialog({
  open,
  setOpen,
  user,
  getData,
}) {
  const output = {
    isFreebie: user?.isFreebie || user?.subscription?.isFreebie,
    plan: user?.subscription?.planName || user?.subscription?.plan,
    status: user?.subscription?.status,

    stripeCustomerId: user?.subscription?.stripeCustomerId,
    userId: user?._id,
    subscriptionId: user?.subscription?.subscriptionId,

    billingCycleAnchor: user?.subscription?.billingCycleAnchor,
    currentPeriodStart: user?.subscription?.currentPeriodStart,
    currentPeriodEnd: user?.subscription?.currentPeriodEnd,
    trialStart: user?.subscription?.trialStart,
    trialEnd: user?.subscription?.trialEnd,
    canceledAt: user?.subscription?.canceledAt,
    cancelAtPeriodEnd: user?.subscription?.cancelAtPeriodEnd,
    nextBillingDate: user?.subscription?.nextBillingDate,
    subscriptionItemId: user?.subscription?.subscriptionItemId,

    // checkoutSessionId: user?.subscription?.checkoutSessionId,
    // invoice: user?.subscription?.invoice,
    // subscriptionId: user?.subscription?.subscriptionId,

    // priceId: user?.subscription?.priceId,
    // name: user?.subscription?.name,

    // price: user?.subscription?.price,
    // status: user?.subscription?.status,
    // startDate: user?.subscription?.startDate,
    // endDate: user?.subscription?.endDate,
  };
  console.log(user);

  const [loading, setLoading] = useState(false);

  const handleSubscribe = (isFreebie) => {
    setLoading(true);
    httpClient()
      .put(`/users/${user?._id}`, {
        isFreebie: isFreebie,
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        getData();
        setOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleUnsubscribe = () => {
    setLoading(true);

    if (user.isFreebie) {
      handleSubscribe(false);
      return;
    }

    httpClient()
      .post(`/subscription/cancel`, {
        userId: user?._id,
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        getData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return loading ? (
    <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Dialog
      onClose={() => setOpen((e) => !e)}
      open={open}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        ".MuiPaper-root": { borderRadius: 8 },
      }}
    >
      <DialogTitle sx={{ padding: "20px 20px 0 20px" }}>
        Subscribe Status
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: 20,
        }}
      >
        <SI textSx={{ fontWeight: 500 }} label="Key" value="Value" />

        <Box sx={{ borderBottom: `1px #00000022 solid` }} />

        <SI label="isFreebie" value={output.isFreebie ? "Yes" : "No"} />
        <SI label="plan" value={output.plan} />

        <SI label="status" value={capitalizeFirstLetter(output.status)} />

        <SI
          label="stripeCustomerId"
          value={
            <CopiableText text={output.stripeCustomerId?.substring(0, 10)} />
          }
        />

        <SI
          label="userId"
          value={<CopiableText text={output.userId?.substring(0, 10)} />}
        />

        <SI
          label="subscriptionId"
          value={
            <CopiableText text={output.subscriptionId?.substring(0, 10)} />
          }
        />

        <SI
          label="billingCycleAnchor"
          value={moment(output.billingCycleAnchor).format("MMMM DD, YYYY")}
        />
        <SI
          label="currentPeriodStart"
          value={moment(output.currentPeriodStart).format("MMMM DD, YYYY")}
        />

        <SI
          label="currentPeriodEnd"
          value={moment(output.currentPeriodEnd).format("MMMM DD, YYYY")}
        />
        <SI
          label="trialStart"
          value={moment(output.trialStart).format("MMMM DD, YYYY")}
        />
        <SI
          label="trialEnd"
          value={moment(output.trialEnd).format("MMMM DD, YYYY")}
        />
        <SI
          label="canceledAt"
          value={moment(output.canceledAt).format("MMMM DD, YYYY")}
        />
        <SI label="cancelAtPeriodEnd" value={output.cancelAtPeriodEnd} />
        <SI
          label="nextBillingDate"
          value={moment(output.nextBillingDate).format("MMMM DD, YYYY")}
        />
        <SI
          label="subscriptionItemId"
          value={
            <CopiableText text={output.subscriptionItemId?.substring(0, 10)} />
          }
        />
      </DialogContent>

      <DialogActions
        sx={{
          gap: 20,
          padding: 20,

          display: "flex",
        }}
      >
        {(["active", "trialing"].includes(user?.subscription?.status) ||
          user.isFreebie) && (
          <Button
            sx={{
              backgroundColor: "#d32f2f22",
              color: "#d32f2f",
              px: 20,
              py: 10,
              borderRadius: 5,
              textTransform: "none",
              width: "100%",

              transition: "all .2s ease-in-out",
              "&:hover": {
                backgroundColor: "#d32f2f44",
              },
            }}
            onClick={() => handleUnsubscribe()}
          >
            Unsubscribe
          </Button>
        )}
        <Button
          sx={{
            backgroundColor: "#2e7d3222",
            color: "#2e7d32",
            px: 20,
            py: 10,
            borderRadius: 5,
            textTransform: "none",
            width: "100%",

            transition: "all .2s ease-in-out",
            "&:hover": {
              backgroundColor: "#2e7d3244",
            },
          }}
          onClick={() => handleSubscribe(true)}
        >
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const SI = ({ label, value, valueRow, textSx }) => {
  return !value && !valueRow ? (
    <div />
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: ".9rem", ...textSx }}>
          {convertToTitleCase(label)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {valueRow || (
          <Typography sx={{ fontSize: ".9rem", ...textSx }}>{value}</Typography>
        )}
      </Box>
    </Box>
  );
};

const CopiableText = ({ text }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <ButtonBase
      sx={{
        padding: "5px 10px",
        backgroundColor: theme.textColor + 11,
        borderRadius: 5,

        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      <Typography
        sx={{
          fontSize: ".9rem",
        }}
      >
        {text}...
      </Typography>

      <ContentCopyIcon sx={{ fontSize: "1rem" }} />
    </ButtonBase>
  );
};

import React, { createRef, useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ThemeContext } from "../../context/ThemeContext";
import { AuthContext } from "../../context/AuthContext";
import CustomButton from "../../components/customStyles/CustomButton";

import CustomInput from "../../components/customStyles/CustomInput";
import { useParams, useNavigate } from "react-router-dom";
import logo_text from "../../assets/logo_text.svg";
import httpClient from "../../libs/httpClient";
import { SnackbarContext } from "../../context/SnackbarContext";

export default function ResetPasswordPage() {
  const { confirmationCode } = useParams();
  const navigate = useNavigate();

  const snackbarContext = useContext(SnackbarContext);
  const { theme } = useContext(ThemeContext);
  const { requestPasswordReset, currentPass } = useContext(AuthContext);
  // const recaptchaRef = createRef();

  // const [token, setToken] = useState();
  // const [email, setEmail] = useState("");

  // const handleSubmit = (e) => {
  //   e && e?.preventDefault();
  //   requestPasswordReset(email, token);
  // };

  const [loading, setLoading] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(false);
  // Check if confirmationCode is valid

  useEffect(() => {
    if (confirmationCode) {
      // requestPasswordReset(confirmationCode);
      handleCheckCode();
    }
  }, [confirmationCode]);

  const handleCheckCode = () => {
    httpClient()
      .get(`/auth/reset-password/${confirmationCode}`)
      .then((res) => {
        console.log(res);

        const status = res?.data?.status;

        if (status === "success") {
          setIsCodeValid(true);
        } else {
          setIsCodeValid(false);
          snackbarContext.message(status, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle reset password
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = (e) => {
    e && e?.preventDefault();
    if (password !== confirmPassword) {
      snackbarContext.message("Passwords do not match", "error");
      return;
    }
    setLoading(true);
    httpClient()
      .post(`/auth/reset-password/${confirmationCode}`, { password })
      .then((res) => {
        console.log(res);
        setLoading(false);
        snackbarContext.message("Password reset successful", "success");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        snackbarContext.message("Password reset failed", "error");
      });
  };

  return (
    <div className="feedbackPage">
      <div className="feedbackPageContainer">
        <div
          className="feedbackForm"
          style={{
            minHeight: 300,
            maxHeight: 300,

            justifyContent: "center",
          }}
        >
          {isCodeValid ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              <h2
                style={{
                  color: theme.textColor,
                  textAlign: "left",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                Reset Password
              </h2>
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
                onSubmit={handleResetPassword}
              >
                <CustomInput
                  type="password"
                  label="Password"
                  placeholder="********"
                  value={password}
                  setValue={setPassword}
                />

                <CustomInput
                  type="password"
                  label="Confirm Password"
                  placeholder="********"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                />

                <CustomButton
                  type="submit"
                  label="Reset Password"
                  loading={loading}
                  onClick={handleResetPassword}
                  // disabled={!token}
                />
              </form>
            </div>
          ) : (
            <p
              style={{
                color: theme.textColor2,
                fontSize: ".8rem",
                textAlign: "center",
              }}
            >
              We want to make sure it's really you. In order to further verify
              your identity, click on the verification link that we sent to
              reset password.
            </p>
          )}
        </div>

        <img className="LogoText" src={logo_text} alt="logo_text" />
      </div>
    </div>
  );
}

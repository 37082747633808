import "./homePage.css";
import Header from "../../components/Header";

import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import TopSection from "./_components/TopSection";
import { Box } from "@mui/material";
import KeyFeaturesSection from "./_components/KeyFeaturesSection";
import HowItWorksSection from "./_components/HowItWorksSection";
import PricingSection from "./_components/PricingSection";
import Footer from "../../components/Footer";
import CTASection from "./_components/CTASection";
import ContactUsSection from "./_components/ContactUsSection";
import { AuthContext } from "../../context/AuthContext";

export default function HomePage() {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          background:
            theme.name === "light"
              ? "#F5F7F9"
              : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
          minHeight: 700,
          // height: "100svh",

          height: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Header />

        <TopSection />
      </Box>

      <KeyFeaturesSection />

      <HowItWorksSection />

      {!["active", "trialing"].includes(user?.subscription?.status) && (
        <PricingSection />
      )}

      <CTASection />

      <ContactUsSection />

      <Footer />
    </>
  );
}

import React, { useContext, useState } from "react";
import Header from "../../components/Header";
import { Box, ButtonBase } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { ThemeContext } from "../../context/ThemeContext";

import premium from "../../assets/pages/subscriptionWarningPage/premium.png";
import essential from "../../assets/pages/subscriptionWarningPage/essential.png";
import { loadStripe } from "@stripe/stripe-js";
import httpClient from "../../libs/httpClient";
import LoadingPage from "../../components/LoadingPage";
import SubscriptionDialog from "../../dialog/SubscriptionDialog";

export default function SubscriptionWarningPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  // const [loading, setLoading] = useState(false);

  // const makePayment = async ({ price }) => {
  //   setLoading(true);
  //   const stripe = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

  //   const body = { price };

  //   httpClient()
  //     .post("/payment/create-checkout-session", body)
  //     .then((res) => {
  //       const session = res.data;
  //       console.log(session);
  //       stripe.redirectToCheckout({ sessionId: session.id });
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };

  return (
    <>
      {/* {loading && <LoadingPage fullSize={true} />} */}

      <Header />
      <Box
        sx={{
          position: "relative",
          background: "#F5F7F9",
          minHeight: "max(700px, calc(100vh - 60px))",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            height: "100%",
            maxHeight: 600,

            paddingInline: 20,
            paddingBlock: 40,

            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            gap: 30,
            borderRadius: 10,
            backgroundColor: theme.ui,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <WarningRoundedIcon
              sx={{
                fontSize: "4rem",
                color: "#be9358",
              }}
            />

            <h1
              style={{
                fontSize: "2.5rem",
              }}
            >
              Warning
            </h1>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 40,
            }}
          >
            <h3
              style={{
                color: theme.textColor,
                textAlign: "center",
                width: "100%",
                fontWeight: 600,
                lineHeight: "1.5",
              }}
            >
              You don't have an active subscription. <br />
              Please subscribe to continue using the service.
            </h3>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
              }}
            >
              <ButtonBase
                sx={{ borderRadius: 10 }}
                onClick={() => {
                  setShowSubscriptionDialog("monthly");
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={essential}
                  alt="essential"
                />
              </ButtonBase>

              <ButtonBase
                sx={{ borderRadius: 10 }}
                onClick={() => {
                  setShowSubscriptionDialog("yearly");
                }}
              >
                <img style={{ width: "100%" }} src={premium} alt="premium" />
              </ButtonBase>
            </Box>
          </Box>

          <p style={{ textAlign: "center", fontSize: ".8rem" }}>
            Privil.link requers a subscription to use. <br />
            You can subscribe by clicking the button below.
          </p>
        </Box>
      </Box>

      <SubscriptionDialog
        open={showSubscriptionDialog}
        setOpen={setShowSubscriptionDialog}
      />
    </>
  );
}

import { Box } from "@mui/material";
import React, { useContext, useState } from "react";

import { ThemeContext } from "../../../context/ThemeContext";
import SubscriptionPriceItem from "../../../components/SubscriptionPriceItem";
import SubscriptionDialog from "../../../dialog/SubscriptionDialog";

export default function PricingSection() {
  const { theme, bp } = useContext(ThemeContext);

  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  return (
    <>
      <Box
        id="pricing"
        sx={{
          minHeight: 700,
          // height: "100svh",
          // height: "max(100%, 100svh)",
          width: "100%",

          paddingBlock: 100,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 1250,
            height: "100%",

            paddingInline: 20,

            display: "flex",
            flexDirection: "column",
            justifyContent: "cenrer",
            alignItems: "center",
            gap: 80,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontSize: bp?.md ? 38 : 42,
                color: theme.textColor,
                fontWeight: 600,
                textAlign: bp?.md ? "center" : "left",
              }}
            >
              Pricing
            </h1>

            <p
              style={{
                fontSize: bp?.md ? 14 : 18,
              }}
            >
              Empower Your Restaurant with Real-Time Feedback
            </p>
          </Box>

          <Box
            style={{
              color: theme.textColor2,

              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: 30,
              width: "100%",
              maxWidth: 750,

              alignItems: "center",
            }}
          >
            <SubscriptionPriceItem
              type="Monthly"
              price="5.99"
              // description="Per user, per month. Billed monthly."
              description="Try it free for 3 months!"
              isPopular={false}
              actionButtonLabel="Get Started"
              handleSubmit={() => setShowSubscriptionDialog("monthly")}
            />

            <SubscriptionPriceItem
              type="Yearly"
              price="64.70"
              // description="Per user, per year. Billed annually."
              description="Try it free for 3 months!"
              isPopular={true}
              popularText="Save 10%"
              actionButtonLabel="Subscribe now"
              handleSubmit={() => setShowSubscriptionDialog("yearly")}
            />

            {/* <SubscriptionPriceItem
            type="Essential"
            price="2.99"
            // description="Per user, per month. Billed annually."
            description=""
            isPopular={false}
            actionButtonLabel="Get Started"
          />

          <SubscriptionPriceItem
            type="Premium"
            price="7.99"
            // description="Per user, per month. Billed annually."
            description=""
            isPopular={true}
            actionButtonLabel="Subscribe now"
          /> */}
          </Box>

          {/* <h5
              style={{
                fontSize: bp?.md ? 18 : 22,
                lineHeight: 1.25,
                fontWeight: 500,
                color: theme.textColor2,
                textAlign: bp?.md ? "center" : "left",
              }}
            >
              Empower Your Restaurant with Real-Time Feedback
            </h5> */}
        </Box>
      </Box>

      <SubscriptionDialog
        open={showSubscriptionDialog}
        setOpen={setShowSubscriptionDialog}
      />
    </>
  );
}

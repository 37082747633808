import { Button, Skeleton, ButtonBase } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import QRCodeItem from "./components/QRCodeItem";

import { ThemeContext } from "../../../context/ThemeContext";
import httpClient from "../../../libs/httpClient";

export default function QRCodesPage() {
  const { theme } = useContext(ThemeContext);
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCodes();
  }, []);

  const getCodes = () => {
    setLoading(true);
    httpClient()
      .get("/qrCodes")
      .then((res) => {
        console.log(res.data);
        setCodes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(args) {
    var data, filename, link;

    let emails = [];

    codes?.map((code) => {
      code?.feedbacks?.map((feedback) => {
        emails.push({
          qRCode: code.code,
          feedback: feedback.feedback,
          rate: feedback.rate || "",
          createdAt: feedback.createdAt,
        });
      });
    });

    // codes?.feedbacks?.map((p) => {
    //   emails.push({
    //     feedback: p.feedback,
    //     rate: p.rate || "",
    //     createdAt: p.createdAt,
    //   });
    // });

    console.log(emails);
    // CreatedAT: moment(item.createdAt).format("MMMM d, YYYY"),

    var csv = convertArrayOfObjectsToCSV({
      data: emails,
    });
    if (csv == null) return;

    filename = args.filename || "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <Header />
      <div className="qrCodeGenPageContainer">
        <div
          className="qrCodeGenPage"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <h1>QR Codes</h1>
            <div>
              <Button
                sx={{
                  background:
                    "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  color: "#FFF",
                  textTransform: "none",
                  borderRadius: 5,
                  marginRight: 20,
                }}
                onClick={() => {
                  downloadCSV({ filename: `All QR Codes.csv` });
                }}
              >
                Download all Feedbacks
              </Button>

              <Link
                to="/admin/qrCodes/generate"
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color: "#FFF",
                    textTransform: "none",
                    borderRadius: 5,
                  }}
                >
                  Generate QR Code
                </Button>
              </Link>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              width: "100%",
              gridTemplateColumns:
                codes.length <= 0 && !loading
                  ? "1fr"
                  : "repeat(auto-fill, minmax(150px, 1fr))",
              gap: 20,
              marginTop: 20,
              marginBottom: 20,
              height: "100%",
            }}
          >
            {loading ? (
              <>
                {Array.from(new Array(18)).map((item, index2) => (
                  <Skeleton
                    key={index2}
                    variant="rectangular"
                    width={"100%"}
                    height={160}
                    style={{ borderRadius: theme.borderRadius }}
                  />
                ))}
              </>
            ) : codes?.length > 0 ? (
              codes?.map((item, index) => (
                <QRCodeItem key={index} data={item} />
              ))
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: theme.textColor2 }}>
                  You don't have any Generated QR Code.
                </p>

                <Link to="/admin/qrCode" style={{ textDecoration: "none" }}>
                  <ButtonBase>
                    <p style={{ color: theme.textColor2 }}>
                      Click here to Generate a QR Code
                    </p>
                  </ButtonBase>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useState } from "react";
import { Box } from "@mui/material";

import { ThemeContext } from "../../context/ThemeContext";
import { AuthContext } from "../../context/AuthContext";
import CustomButton from "../../components/customStyles/CustomButton";

import CustomInput from "../../components/customStyles/CustomInput";
import { Link } from "react-router-dom";

import logo_text from "../../assets/logo_text.svg";

export default function SignupPage() {
  const { theme } = useContext(ThemeContext);
  const { loading, signInWithEmail } = useContext(AuthContext);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e && e?.preventDefault();
    signInWithEmail(fullName, "user", email, password);
  };

  return (
    <div className="feedbackPage">
      <div className="feedbackPageContainer">
        <div
          className="feedbackForm"
          style={{
            minHeight: 400,
            maxHeight: 400,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
              flex: 1,
            }}
          >
            <h2
              style={{
                color: theme.textColor,
                textAlign: "left",
                width: "100%",
                fontWeight: 600,
              }}
            >
              Signup
            </h2>
            <form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
              onSubmit={handleSubmit}
            >
              <CustomInput
                type="userName"
                label="Full Name"
                placeholder="job_bob@example.com"
                value={fullName}
                setValue={setFullName}
              />

              <CustomInput
                type="email"
                label="Email"
                placeholder="job_bob@example.com"
                value={email}
                setValue={setEmail}
              />
              <CustomInput
                type="password"
                label="Password"
                placeholder="********"
                value={password}
                setValue={setPassword}
              />

              <CustomButton
                type="submit"
                label="Signup"
                loading={loading}
                onClick={handleSubmit}
              />

              <Link
                style={{
                  textAlign: "center",
                  marginTop: 0,
                  textDecoration: "none",
                  color: theme.textColor,
                }}
                to="/login"
              >
                <p style={{ color: theme.textColor3 }}>
                  Already have an account?{" "}
                  <span style={{ color: theme.textColor }}>Sign in</span>
                </p>
              </Link>
            </form>
          </div>
        </div>

        <img className="LogoText" src={logo_text} alt="logo_text" />
      </div>
    </div>
  );
}

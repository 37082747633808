import { Box, CircularProgress, ButtonBase } from "@mui/material";
import React, { useContext, useState } from "react";

import { ThemeContext } from "../../../context/ThemeContext";
import CustomInput from "../../../components/customStyles/CustomInput";
import httpClient from "../../../libs/httpClient";
import { SnackbarContext } from "../../../context/SnackbarContext";
import { AuthContext } from "../../../context/AuthContext";

export default function ContactUsSection() {
  const { theme, bp } = useContext(ThemeContext);
  const snackbarContext = useContext(SnackbarContext);

  const { isAuthenticated, user } = useContext(AuthContext);

  const [fullName, setFullName] = useState(user?.fullName);
  const [email, setEmail] = useState(user?.email);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!fullName || !email || !message) {
      snackbarContext.message("Please fill all fields", "error");
      return;
    }

    setLoading(true);

    httpClient()
      .post("/contact", {
        fullName,
        email,
        message,
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);

        if (!isAuthenticated) {
          setFullName("");
          setEmail("");
        }

        setMessage("");

        snackbarContext.message("Message sent successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        snackbarContext.message("Something went wrong", "error");
      });
  };

  return (
    <Box
      id="contact"
      sx={{
        minHeight: 300,
        // height: "100svh",
        height: "max(100%, 100svh)",

        paddingBlock: 100,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,

          display: "flex",
          flexDirection: bp?.md ? "column" : "row",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          gap: 20,
        }}
      >
        <Box sx={{ textAlign: "left", flex: bp?.xl ? 1 : 2 }}>
          <h1
            style={{
              fontSize: bp?.md ? 38 : 42,
              color: theme.textColor,
              fontWeight: 600,
              textAlign: bp?.md ? "center" : "left",
              lineHeight: 1.35,
            }}
          >
            Contact Us
          </h1>

          <p
            style={{
              fontSize: bp?.md ? 14 : 18,
              color: theme.textColor2,
              textAlign: bp?.md ? "center" : "left",
            }}
          >
            Need to get in touch with us? Please fill out the form with
            <br /> your inquiry or send an email to{" "}
            <a href="mailto:jason@privil.link">jason@privil.link</a>.
          </p>
        </Box>

        <Box
          sx={{
            background: "#F5F7F9",
            borderRadius: 10,
            flex: 1,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: 30,
            width: "100%",
          }}
        >
          <CustomInput
            disabled={isAuthenticated}
            type="userName"
            label="Full Name"
            placeholder="Jon Bob"
            value={fullName}
            setValue={setFullName}
          />

          <CustomInput
            disabled={isAuthenticated}
            type="email"
            label="Email"
            placeholder="job_bob@example.com"
            value={email}
            setValue={setEmail}
          />

          <CustomTextarea
            type="message"
            label="Message"
            placeholder="Message"
            value={message}
            setValue={setMessage}
          />

          <ButtonBase
            sx={{
              width: "100%",
              maxWidth: 200,

              background:
                theme.name === "dark"
                  ? "#F5F7F9"
                  : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
              color: theme.name === "dark" ? "#131f33" : "#fff",
              borderRadius: 8,
              height: 45,
              fontSize: 16,
              fontWeight: 600,
              "&:hover": {
                background:
                  theme.name === "dark"
                    ? "#F5F7F9"
                    : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                color: theme.name === "dark" ? "#131f33" : "#fff",

                opacity: 0.85,
              },
            }}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress
                sx={{ color: "#FFF" }}
                thickness={7}
                size={16}
              />
            ) : (
              "Send Message"
            )}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

const CustomTextarea = ({ type, label, placeholder, value, setValue }) => {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 5,

        "& textarea": {
          transition: "all 0.3s ease",

          bgcolor: theme.textColor3 + "1A",
          color: theme.textColor,
          "&:fouse": {
            bgcolor: "red",
            borderColor: "red",
          },
          "&:hover": {
            bgcolor: theme.textColor3 + "4D",
            filter: "opacity(85%)",
          },
        },
      }}
    >
      <h5
        style={{
          fontWeight: 500,
          color: theme.textColor2,
        }}
      >
        {label}
      </h5>

      <textarea
        style={{
          width: "100%",
          height: 100,
          borderRadius: 8,
          border: "1px solid #DDE2E5",
          padding: "7px 10px",
          fontSize: 16,
          color: "#000",
          fontWeight: 400,

          resize: "vertical",
        }}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Box>
  );
};

import { Button, Rating, useMediaQuery } from "@mui/material";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

import { toSvg } from "html-to-image";

import Header from "../../components/Header";
import "../qrCodeGeneratorPage/QRCodeGeneratorPage.css";
import CustomButton from "../../components/customStyles/CustomButton";
import httpClient from "../../libs/httpClient";
import { SnackbarContext } from "../../context/SnackbarContext";
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import { ThemeContext } from "../../context/ThemeContext";
import moment from "moment";

export default function QRCodePage() {
  const { theme } = useContext(ThemeContext);
  const max600 = useMediaQuery("(max-width:600px)");

  const { id } = useParams();

  const [codes, setCodes] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    setLoading(true);
    httpClient()
      .get(`/qrCodes/${id}`)
      .then((res) => {
        setCodes(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(args) {
    var data, filename, link;

    let emails = [];
    codes?.feedbacks?.map((p) => {
      emails.push({
        feedback: p.feedback,
        rate: p.rate || "",
        createdAt: p.createdAt,
      });
    });

    console.log(emails);
    // CreatedAT: moment(item.createdAt).format("MMMM d, YYYY"),

    var csv = convertArrayOfObjectsToCSV({
      data: emails,
    });
    if (csv == null) return;

    filename = args.filename || "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <Header />
      {loading ? (
        <LoadingPage fullSize={true} />
      ) : (
        <div className="qrCodeGenPageContainer">
          {!codes ? (
            <div
              className="qrCodeGenPage"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>QR Code Not Found!</p>
            </div>
          ) : (
            <div
              className="qrCodeGenPage"
              style={{
                gap: 20,
                gridTemplateRows: max600 ? "40px 40px auto 1fr" : "40px 1fr",
                gridTemplateColumns: max600 ? "1fr" : "1fr 1fr",
                paddingBottom: max600 ? 20 : 0,
              }}
            >
              <div className="no-print">
                <h1 style={{ textAlign: max600 ? "center" : "left" }}>
                  Feedbacks
                </h1>
              </div>
              <div
                style={{
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                }}
                className="no-print"
              >
                <CustomButton
                  style={{ width: 250 }}
                  onClick={() =>
                    downloadCSV({ filename: `${codes?.code}.csv` })
                  }
                >
                  Download Feedbacks as CSV
                </CustomButton>
              </div>

              <div className="leftSection no-print" style={{ gap: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    paddingBottom: 20,
                  }}
                >
                  {codes?.feedbacks?.length > 0 ? (
                    codes?.feedbacks?.map((item, index) => (
                      <FeedbackCard key={index} item={item} />
                    ))
                  ) : (
                    <p style={{ color: theme.textColor3 }}>
                      No one has given any feedback yet, on this QRCode.
                    </p>
                  )}
                </div>
              </div>
              <RightSection code={codes?.code} url={codes?.url} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

const RightSection = ({ code, url }) => {
  const snackbarContext = useContext(SnackbarContext);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleDownloadQRCode = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toSvg(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${code}.svg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    handleSaveQRCode();
  }, [ref]);

  const handlePrintQRCode = () => {
    handleSaveQRCode();
    window.print();
  };

  const handleSaveQRCode = () => {
    setLoading(true);
    httpClient()
      .post("/qrCodes", { url, code })
      .then((res) => {
        snackbarContext.message("QR Code Saved.", "info");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div id="QRCodePro" className="rightSection" style={{ paddingTop: 0 }}>
      <div ref={ref}>
        <div
          className="QRCode"
          style={{
            width: 275,
            textAlign: "center",
            paddingBottom: 10,
            // marginTop: 30,
            marginBottom: 20,
          }}
        >
          <h3>{code}</h3>
        </div>

        <div className="QRCode">
          <QRCode ref={ref} value={`${url}${code}`} />
        </div>
      </div>
      {/* <div ref={qrCode} className="QRCode">
    <QRCodeSVG value={`${URL}/${code}`} renderAs="canvas" />
  </div> */}
      <Button className="no-print" onClick={() => handleDownloadQRCode()}>
        Download QR Code
      </Button>
      <Button className="no-print" Button onClick={() => handlePrintQRCode()}>
        Print QR Code
      </Button>

      <canvas id="canvas" style={{ display: "none" }} />
    </div>
  );
};

const FeedbackCard = ({ item }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        padding: 20,
        borderRadius: theme.borderRadius,
        backgroundColor: theme.ui,
        width: "100%",
        maxWidth: 600,
      }}
    >
      <p style={{ color: theme.textColor }}>{item.feedback}</p>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          marginTop: 10,
        }}
      >
        <p style={{ color: theme.textColor3, fontSize: ".8rem" }}>Rate:</p>

        <Rating
          name="customized-10"
          defaultValue={item.rate}
          max={10}
          disabled={true}
        />
      </div>

      <p style={{ color: theme.textColor3, fontSize: ".8rem" }}>
        {item.createdAt && moment(item.createdAt).format("MMMM D, YYYY")}
      </p>
      {/* {JSON.stringify(item)} */}
    </div>
  );
};

import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button } from "@mui/material";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { SnackbarContext } from "../context/SnackbarContext";
import { AuthContext } from "../context/AuthContext";
import httpClient from "../libs/httpClient";
import { Link } from "react-router-dom";
import CustomInput from "../components/customStyles/CustomInput";
import { ThemeContext } from "../context/ThemeContext";
import capitalizeFirstLetter from "../libs/capitalizeFirstLetter";

export default function SubscriptionDialog({ open, setOpen }) {
  const { message } = useContext(SnackbarContext);
  const { user, toggleAuth } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: user?.email,
      },
    });

    if (error) {
      console.log(error);
      setError(error.message);
      return;
    }

    // Send payment method and customer details to the server
    // const response = await fetch("/create-subscription", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     customerId,
    //     paymentMethodId: paymentMethod.id,
    //     priceId,
    //   }),
    // });

    setLoading(true);
    httpClient()
      .post("/public/subscriptions/subscribe", {
        paymentMethodId: paymentMethod.id,
        // customerId: user?.stripeCustomerId,
        // email: user?.email,
        email,
        password,

        // customerId
        // plan: type === "Monthly" ? "monthly" : "yearly",
        plan: open,
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status !== 200) {
          setError("Failed to create subscription.");
          message("Failed to create subscription.", "error");
          return;
        }

        message(
          // "Successfully subscribed. Please check your email for future instructions.",
          "Thank you for subscribing to our service! We will send you an email with QR code and instruction shortly!",
          "success"
        );
        setOpen(false);
        setError(null);

        toggleAuth();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Failed to create subscription.");
        message("Failed to create subscription.", "error");
      });

    // if (!response.ok) {
    //   setError("Failed to create subscription.");
    // } else {
    //   // Successful subscription creation handling
    // }
  };

  return (
    <Dialog
      onClose={() => setOpen((e) => !e)}
      open={open}
      fullWidth={true}
      maxWidth="xs"
      sx={{
        ".MuiPaper-root": { borderRadius: 8 },
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle sx={{ padding: "20px 20px 0 20px" }}>
        Join for {capitalizeFirstLetter(open || "")} Plan
      </DialogTitle>

      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 16,
          gap: 16,
        }}
      >
        {/* <Box>
          <h5>Email</h5>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%",
              height: 48,
              borderRadius: 8,
              border: "1px solid #00000033",
            }}
          />
        </Box> */}
        <CustomInput
          type="email"
          label="Email"
          placeholder="job_bob@example.com"
          value={email}
          setValue={setEmail}
          sx={{ height: 48, backgroundColor: theme.ui }}
        />
        <CustomInput
          type="password"
          label="Password"
          placeholder="********"
          value={password}
          setValue={setPassword}
          sx={{ height: 48, backgroundColor: theme.ui }}
        >
          <p
            style={{
              fontSize: 14,
              color: "#000000aa",
              marginTop: 8,
            }}
          >
            Enter a new password to confirm subscription.
          </p>
        </CustomInput>

        <div>
          <Box>
            <h5
              style={{
                fontWeight: 500,
                paddingBottom: 5,
                color: theme.textColor2,
              }}
            >
              Add Card Details:
            </h5>
          </Box>

          <Box
            sx={{
              padding: 16,
              borderRadius: 8,
              border: "1px solid #00000033",
            }}
          >
            <CardElement />
          </Box>
          <p style={{ paddingTop: 8 }}>
            You are subscribing to our {open} plan. You will be charged $
            {open === "monthly" ? 5.99 : 64.7} after the trial period ends. You
            can cancel anytime.,
          </p>
        </div>

        {error && (
          <p
            style={{
              fontSize: 14,
              color: "red",

              marginTop: 16,
            }}
          >
            {error}
          </p>
        )}

        <p
          style={{
            fontSize: 14,
            color: "#000000aa",

            marginTop: 16,
          }}
        >
          Enter your card details to subscribe to our {open} plan. You can
          cancel anytime. We do not store your card details. Your card details
          are securely stored by our payment processor, Stripe.
          <br />
          <br />
          By subscribing, you agree to our terms and conditions. You can view
          our terms and conditions by clicking <Link to="/tos">here</Link>.
        </p>
      </DialogContent>

      <DialogActions
        sx={{
          gap: 20,
          padding: 20,

          display: "flex",
        }}
      >
        <Button
          sx={{
            color: "#000000",
            backgroundColor: "#00000011",
            padding: "6px 16px",

            textTransform: "none",
            fontWeight: 500,
            borderRadius: 6,
            border: `1px solid #00000033`,
          }}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>

        <Button
          sx={{
            color: "#ffffff",
            backgroundColor: "#000000",
            padding: "6px 16px",

            textTransform: "none",
            fontWeight: 500,
            borderRadius: 6,

            "&:hover": {
              backgroundColor: "#000000cc",
            },
          }}
          onClick={() => handleSubmit()}
        >
          Subscribe
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { createRef, useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ThemeContext } from "../../context/ThemeContext";
import { AuthContext } from "../../context/AuthContext";
import CustomButton from "../../components/customStyles/CustomButton";

import CustomInput from "../../components/customStyles/CustomInput";
import { Link } from "react-router-dom";
import logo_text from "../../assets/logo_text.svg";

export default function LoginPage() {
  const { theme } = useContext(ThemeContext);
  const { loading, loginWithEmail, currentPass } = useContext(AuthContext);
  const recaptchaRef = createRef();

  const [token, setToken] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e && e?.preventDefault();
    loginWithEmail(email, password, token);
  };

  return (
    <div className="feedbackPage">
      <div className="feedbackPageContainer">
        <div
          className="feedbackForm"
          style={{
            minHeight: 450,
            maxHeight: 450,

            justifyContent: "center",
          }}
        >
          {!currentPass ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              <h2
                style={{
                  color: theme.textColor,
                  textAlign: "left",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                Login
              </h2>
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
                onSubmit={handleSubmit}
              >
                <CustomInput
                  type="email"
                  label="Email"
                  placeholder="job_bob@example.com"
                  value={email}
                  setValue={setEmail}
                />
                <CustomInput
                  type="password"
                  label="Password"
                  placeholder="********"
                  value={password}
                  setValue={setPassword}
                />

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Link
                    to="/forgot-password"
                    style={{ color: theme.buttonColor, textDecoration: "none" }}
                  >
                    <p style={{ fontSize: 12 }}>Forgot password?</p>
                  </Link>
                </div>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                  onChange={(e) => setToken(e)}
                />

                <CustomButton
                  type="submit"
                  label="Login"
                  loading={loading}
                  onClick={handleSubmit}
                  disabled={
                    process.env.REACT_APP_ENVIRONMENT === "development"
                      ? false
                      : !token
                  }
                />

                <Link
                  style={{
                    textAlign: "center",
                    marginTop: 0,
                    textDecoration: "none",
                    color: theme.textColor,
                  }}
                  to="/admin/signup"
                >
                  <p style={{ color: theme.textColor3 }}>
                    Don't have an account?{" "}
                    <span style={{ color: theme.textColor }}>Sign up</span>
                  </p>
                </Link>
              </form>
            </div>
          ) : (
            <p
              style={{
                color: theme.textColor2,
                fontSize: ".8rem",
                textAlign: "center",
              }}
            >
              We want to make sure it's really you. In order to further verify
              your identity, click on the verification link that we sent to
              login.
            </p>
          )}
        </div>

        <img className="LogoText" src={logo_text} alt="logo_text" />
      </div>
    </div>
  );
}

import React, { createContext, useState } from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const SnackbarContext = createContext();

export const SnackbarContextProvider = (props) => {
  const [showNotification, setShowNotification] = useState(false);

  const [msg, setMsg] = useState("");
  const [type, setType] = useState("success" || "error" || "info" || "warning");
  const [anchorOrigin, setAnchorOrigin] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowNotification(false);
  };

  const Message = (msg, type, anchorOriginProps) => {
    setMsg(msg);
    setType(type);
    setShowNotification(true);

    setAnchorOrigin(
      anchorOriginProps || {
        vertical: "bottom",
        horizontal: "left",
      }
    );
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <SnackbarContext.Provider
      value={{
        showNotification: setShowNotification,
        msg: setMsg,
        type: setType,
        message: Message,
      }}
    >
      <Stack spacing={0} sx={{ width: "100%" }}>
        {props.children}
        <Snackbar
          open={showNotification}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={type || "success"}
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </Stack>
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;

import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Box, ButtonBase } from "@mui/material";

export default function SubscriptionPriceItem({
  type,
  price,
  description,
  isPopular,
  popularText,
  actionButtonLabel,
  handleSubmit,
}) {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F7F9",
        padding: 20,
        borderRadius: 16,

        minHeight: 260,
        width: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 50,
            width: "100%",
            borderRadius: 8,

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              color: "#000",

              fontSize: 24,
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            {type}
          </h1>

          {isPopular && (
            <Box
              sx={{
                background:
                  theme.name === "dark"
                    ? "#F5F7F9"
                    : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                color: theme.name === "dark" ? "#131f33" : "#fff",

                padding: "4px 24px",
                borderRadius: 30,

                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              <p>{popularText || "Popular"}</p>
            </Box>
          )}
        </Box>

        <p
          style={{
            color: "#000000",
            fontSize: 16,
            marginBottom: 20,
          }}
        >
          {description}
        </p>

        <h4
          style={{
            color: "#000",
            fontSize: bp?.md ? 38 : 42,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          ${price}
        </h4>
      </Box>

      <ButtonBase
        sx={{
          marginTop: 40,

          background: isPopular
            ? theme.name === "dark"
              ? "#F5F7F9"
              : "linear-gradient(90deg, #213659 0%, #131f33 100%)"
            : "transparent",

          borderColor: isPopular ? "transparent" : "#213659",
          borderWidth: 2,
          borderStyle: "solid",

          color: isPopular
            ? theme.name === "dark"
              ? "#131f33"
              : "#fff"
            : theme.buttonColor,

          borderRadius: 15,

          fontSize: 18,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",

          width: "100%",
          height: 55,
        }}
        // onClick={isAuthenticated ? makePayment : redirectToLogin}
        onClick={() => handleSubmit()}
      >
        <h4
          style={{
            color:
              theme.name === "dark"
                ? "#F5F7F9"
                : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          {actionButtonLabel}
        </h4>
      </ButtonBase>
    </Box>
  );
}

import { IconButton, useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useContext, useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Header from "../../components/Header";
import "../qrCodeGeneratorPage/QRCodeGeneratorPage.css";
import httpClient from "../../libs/httpClient";
import LoadingPage from "../../components/LoadingPage";
import { ThemeContext } from "../../context/ThemeContext";
import moment from "moment";

export default function AdminContactPage() {
  const { theme } = useContext(ThemeContext);
  const max600 = useMediaQuery("(max-width:600px)");

  const [contact, setContact] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    httpClient()
      .get(`/contact`)
      .then((res) => {
        setContact(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      {loading ? (
        <LoadingPage fullSize={true} />
      ) : (
        <div className="qrCodeGenPageContainer">
          <div
            className="qrCodeGenPage"
            style={{
              gap: 20,
              display: "flex",
              flexDirection: "column",

              paddingBottom: max600 ? 20 : 0,
            }}
          >
            <h1
              style={{ textAlign: max600 ? "center" : "left", marginTop: 20 }}
            >
              Messages from Contact Us
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                paddingBottom: 20,
              }}
            >
              {contact?.length > 0 ? (
                contact?.map((item, index) => (
                  <ContactCard key={index} item={item} getData={getData} />
                ))
              ) : (
                <p style={{ color: theme.textColor3 }}>
                  No one has given any feedback yet, on this QRCode.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const ContactCard = ({ item, getData }) => {
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    httpClient()
      .delete(`/contact/${item._id}`)
      .then((res) => {
        console.log(res.data);
        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        padding: 20,
        borderRadius: theme.borderRadius,
        backgroundColor: theme.ui,
        width: "100%",
        maxWidth: 600,
        position: "relative",
      }}
    >
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <IconButton
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
        }}
        color="error"
        onClick={() => handleDelete()}
      >
        <DeleteIcon />
      </IconButton>
      <p style={{ color: theme.textColor }}>{item.feedback}</p>

      <p style={{ color: theme.textColor2, fontWeight: 500 }}>
        <span style={{ color: theme.textColor3, fontWeight: 400 }}>Name:</span>{" "}
        {item.fullName}
      </p>
      <p style={{ color: theme.textColor2, fontWeight: 500 }}>
        <span style={{ color: theme.textColor3, fontWeight: 400 }}>Email:</span>{" "}
        {item.email}
      </p>

      <p
        style={{
          color: theme.textColor2,

          backgroundColor: theme.backgroundColor,
          borderRadius: theme.borderRadius,
          padding: "5px 10px",
          marginBlock: 10,
        }}
      >
        {item.message}
      </p>

      <p
        style={{
          color: theme.textColor3,
          fontSize: ".8rem",
        }}
      >
        {item.createdAt && moment(item.createdAt).fromNow()}
      </p>
    </div>
  );
};

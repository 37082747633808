import { LoadingButton } from "@mui/lab";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

import { CircularProgress } from "@mui/material";

export default function CustomButton(props) {
  const { type, label, loading, onClick, style, sx: sxStyle, disabled } = props;
  const { theme } = useContext(ThemeContext);

  const itemStyle = {
    width: "100%",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "7.75px 0px",
    borderRadius: theme.borderRadius,
    // backgroundColor: theme.buttonColor,
    background: `linear-gradient(90deg, ${theme.buttonColor1} 0%, ${theme.buttonColor2} 100%)`,

    color: theme.buttonTextColor,
    transition: "all 0.21s ease-in-out",
    "&:hover": {
      backgroundColor:
        sxStyle?.backgroundColor || style?.backgroundColor || theme.buttonColor,
      // backgroundColor: theme.buttonColor,
      // filter: "opacity(85%)",
      // filter: "invert(25%)",
      filter: "contrast(75%)",
    },
  };

  return (
    <LoadingButton
      type={type}
      sx={{
        ...itemStyle,
        ...style,
        ...sxStyle,
      }}
      style={{
        textTransform: "none",
        // ...style,
        background: disabled && "rgba(0,0,0,.25)",
      }}
      loading={loading ? loading : undefined}
      loadingIndicator={
        <CircularProgress style={{ color: "#FFF" }} size={16} thickness={7} />
      }
      onClick={() => onClick() || {}}
      disabled={disabled}
    >
      <p style={{ minHeight: 22.5 }}>{loading ? "" : label}</p>
      {props.children}
    </LoadingButton>
  );
}

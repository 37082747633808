import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import { AuthContext } from "../../context/AuthContext";

export default function ConfirmationPage() {
  const { confirmationCode } = useParams();
  const { loginWithCode } = useContext(AuthContext);

  useEffect(() => {
    loginWithCode(confirmationCode);
  }, [confirmationCode]);
  return <LoadingPage fullSize={true} isHomePage />;
}

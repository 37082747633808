import { Box } from "@mui/material";
import Header from "../components/Header";
import { ThemeContext } from "../context/ThemeContext";
import { useContext } from "react";

export default function DashboardPage() {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Header />

      <div className="qrCodeGenPageContainer">
        <div
          className="qrCodeGenPage"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: 450,
              height: "100%",
              maxHeight: 600,

              paddingInline: 20,
              paddingBlock: 40,

              display: "grid",
              //   flexDirection: "column",
              gridTemplateRows: "auto 1fr",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",

              gap: 20,
              borderRadius: 16,
              backgroundColor: "#fff",
            }}
          >
            <h1
              style={{
                color: theme.textColor,
              }}
            >
              Thank you for signing up!
            </h1>

            <p>
              Thanks for signing up! Our team will genarate your QR code and
              send it to you shortly. Please check your email for your QR code.
              If you have any questions, please contact us at{" "}
              <a href="mailto:jameschae81@gmail.com">Email Address</a>.
            </p>
          </Box>
        </div>
      </div>
    </>
  );
}

import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { CircularProgress } from "@mui/material";

export default function LoadingPage({
  fullSize,
  size,
  thickness,
  msg,
  isHomePage,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: fullSize ? "100vh" : "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.ui,

        background: isHomePage
          ? "linear-gradient(90deg, #213659 0%, #131f33 100%)"
          : theme.ui,
      }}
    >
      <CircularProgress
        // style={{ color: theme.buttonColor }}
        size={size || 64}
        thickness={thickness || 5}
        style={{ color: isHomePage ? "#FFF" : theme.textColor }}
      />

      {msg && <p style={{ color: theme.textColor2, marginTop: 20 }}>{msg}</p>}
    </div>
  );
}

import { Box } from "@mui/material";
import React, { useContext } from "react";

import { ThemeContext } from "../../../context/ThemeContext";

const heroImage2 = "https://s3.us-west-2.amazonaws.com/cdn.privil.link/1.jpg";

export default function KeyFeaturesSection() {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        minHeight: 700,
        height: "100svh",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.name === "light" ? "#F5F7F9" : "#131f33",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,

          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          gap: 60,
        }}
      >
        {!bp?.md && (
          <Box>
            <img
              style={{ maxWidth: 400, borderRadius: 50 }}
              src={heroImage2}
              alt="hero image 2"
            />
          </Box>
        )}

        <Box style={{ color: theme.textColor2 }}>
          <h1
            style={{
              fontSize: bp?.md ? 38 : 42,
              color: theme.textColor,
              fontWeight: 600,
              textAlign: bp?.md ? "center" : "left",
              marginBottom: 30,
              marginLeft: 10,
            }}
          >
            Key Features
          </h1>

          <Box
            sx={{
              lineHeight: 1.25,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <LI>Real-Time Feedback: Collect anonymous feedback instantly.</LI>

            <LI>
              QR Code Integration: Easily display QR codes in your restaurant.
            </LI>

            <LI>
              Secure and Private: Feedback is only shared with owners and
              managers by email
            </LI>

            <LI>Low Monthly Subscription: $5.99 per month subscription fee.</LI>
          </Box>
          {/* <h5
            style={{
              fontSize: bp?.md ? 18 : 22,
              lineHeight: 1.25,
              fontWeight: 500,
              color: theme.textColor2,
              textAlign: bp?.md ? "center" : "left",
            }}
          >
            Empower Your Restaurant with Real-Time Feedback
          </h5> */}
        </Box>
      </Box>
    </Box>
  );
}

const LI = (props) => {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        // gap: 10,
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          height: 28,
          minWidth: 24,

          //   backgroundColor: "red",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 6,
            height: 6,
            borderRadius: 50,
            backgroundColor: theme.textColor2,
          }}
        />
      </Box>

      <h4
        style={{
          fontSize: bp?.md ? 18 : 24,
          fontWeight: 500,
          color: theme.textColor2,
          lineHeight: 1.25,
        }}
      >
        {props.children}
      </h4>
    </Box>
  );
};

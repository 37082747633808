import React, { createRef, useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { ThemeContext } from "../../context/ThemeContext";
import { AuthContext } from "../../context/AuthContext";
import CustomButton from "../../components/customStyles/CustomButton";

import CustomInput from "../../components/customStyles/CustomInput";
import { Link } from "react-router-dom";
import logo_text from "../../assets/logo_text.svg";

export default function ForgotPasswordPage() {
  const { theme } = useContext(ThemeContext);
  const { loading, requestPasswordReset, currentPass } =
    useContext(AuthContext);
  const recaptchaRef = createRef();

  const [token, setToken] = useState();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e && e?.preventDefault();
    requestPasswordReset(email, token);
  };

  return (
    <div className="feedbackPage">
      <div className="feedbackPageContainer">
        <div
          className="feedbackForm"
          style={{
            minHeight: 300,
            maxHeight: 300,

            justifyContent: "center",
          }}
        >
          {!currentPass ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              <h2
                style={{
                  color: theme.textColor,
                  textAlign: "left",
                  width: "100%",
                  fontWeight: 600,
                }}
              >
                Forgot Password
              </h2>
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
                onSubmit={handleSubmit}
              >
                <CustomInput
                  type="email"
                  label="Email"
                  placeholder="job_bob@example.com"
                  value={email}
                  setValue={setEmail}
                />

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                  onChange={(e) => setToken(e)}
                />

                <CustomButton
                  type="submit"
                  label="Reset Password"
                  loading={loading}
                  onClick={handleSubmit}
                  disabled={!token}
                />
              </form>
            </div>
          ) : (
            <p
              style={{
                color: theme.textColor2,
                fontSize: ".8rem",
                textAlign: "center",
              }}
            >
              We want to make sure it's really you. In order to further verify
              your identity, click on the verification link that we sent to
              login.
            </p>
          )}
        </div>

        <img className="LogoText" src={logo_text} alt="logo_text" />
      </div>
    </div>
  );
}

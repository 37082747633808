import { Box, ButtonBase, Collapse, Fade } from "@mui/material";
import React, { useContext, useState } from "react";

import { ThemeContext } from "../../../context/ThemeContext";

import QRCodeImage from "../../../assets/pages/homePage/howItWorks/QR_code.png";
import ScanCodeImage from "../../../assets/pages/homePage/howItWorks/scan_code.png";
import RealTimeImage from "../../../assets/pages/homePage/howItWorks/real_time.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

export default function HowItWorksSection() {
  const { user } = useContext(AuthContext);
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        minHeight: 700,
        // height: "100svh",
        height: "max(100%, 100svh)",

        paddingBlock: 100,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,

          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: bp?.md ? "column" : "row",
          gap: 80,
        }}
      >
        <Box
          style={{
            flex: 2,
            color: theme.textColor2,
            display: "flex",
            flexDirection: "column",

            justifyContent: "flex-start",
            alignItems: bp?.md ? "center" : "flex-start",
            textAlign: bp?.md ? "center" : "left",

            gap: 10,
            height: "100%",
            // maxHeight: 562,
            maxHeight: 542,
          }}
        >
          <h1
            style={{
              fontSize: bp?.md ? 38 : 42,
              color: theme.textColor,
              fontWeight: 600,
              textAlign: bp?.md ? "center" : "left",
            }}
          >
            How it Works
          </h1>

          <ButtonBase
            component="a"
            href={user ? "/admin" : "/#pricing"}
            sx={{
              marginTop: 20,
              width: "100%",
              maxWidth: 250,

              background:
                theme.name === "dark"
                  ? "#F5F7F9"
                  : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
              color: theme.name === "dark" ? "#131f33" : "#fff",
              borderRadius: 30,
              height: 55,
              fontSize: 16,
              fontWeight: 600,
              "&:hover": {
                background:
                  theme.name === "dark"
                    ? "#F5F7F9"
                    : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                color: theme.name === "dark" ? "#131f33" : "#fff",

                opacity: 0.85,
              },
            }}
          >
            GET STARTED
          </ButtonBase>
        </Box>

        <Box
          style={{
            flex: 5,
            color: theme.textColor2,

            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: 30,

            // backgroundColor: "red",
          }}
        >
          <HowItWorkItem
            index={1}
            title="Sign up"
            description="Restaurant owners sign up and receive a unique QR code."
          />
          <HowItWorkItem
            index={2}
            title="QR Code"
            description="Display the QR code in your restaurant."
            image={QRCodeImage}
          />
          <HowItWorkItem
            index={3}
            title="Scan Code"
            description="Customers scan the code and provide ratings and feedback."
            image={ScanCodeImage}
          />
          <HowItWorkItem
            index={4}
            title="Real-time"
            description="Owners and managers receive feedback by email in real-time."
            image={RealTimeImage}
          />
        </Box>

        {/* <h5
              style={{
                fontSize: bp?.md ? 18 : 22,
                lineHeight: 1.25,
                fontWeight: 500,
                color: theme.textColor2,
                textAlign: bp?.md ? "center" : "left",
              }}
            >
              Empower Your Restaurant with Real-Time Feedback
            </h5> */}
      </Box>
    </Box>
  );
}

const HowItWorkItem = ({ index, title, description, image }) => {
  const { theme } = useContext(ThemeContext);

  const [hoverd, setHoverd] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F7F9",
        // padding: 30,
        borderRadius: 16,

        minHeight: 275,
        border: `1px solid ${theme.textColor + 22}`,

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",

        position: "relative",

        overflow: "hidden",
      }}
      onMouseEnter={() => setHoverd(true)}
      onMouseLeave={() => setHoverd(false)}
    >
      <Box
        sx={{
          margin: 20,
          backgroundColor: "#78ABFF80",

          height: 50,
          width: 50,
          borderRadius: 8,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          backdropFilter: "blur(4px)",
          zIndex: 99,
        }}
      >
        <h1 style={{ color: "#0060FF" }}>{index}</h1>
      </Box>

      <Box
        sx={{
          padding: "1px 20px 15px 20px",

          // backgroundColor: hoverd ? "#00000033" : "transparent",
          // color: hoverd ? "#fff" : "#000",
          transition: "all 0.2s ease",

          backdropFilter: `blur(${hoverd ? 4 : 0}px)`,
        }}
      >
        <h4
          style={{
            // color: "#000000",
            fontWeight: 700,
            fontSize: 24,
            marginTop: 20,
            // paddingTop: "15px",
          }}
        >
          {title}
        </h4>

        <p
          style={{
            // color: "#000000",
            fontSize: 16,
            marginTop: 10,
          }}
        >
          {description}
        </p>
      </Box>

      {/* 
        <Collapse
          sx={{
            zIndex: 99,
            transition: "all 0.2s ease",
            // boxShadow: hoverd ? "0px 0px 20px 10px #00000055" : "none",
            width: "100%",
          }}
          in={hoverd}
          collapsedSize={70}
        >
          <Box
            sx={{
              padding: "1px 30px 10px 30px",

              backgroundColor: hoverd ? "#00000033" : "transparent",
              color: hoverd ? "#fff" : "#000",
              transition: "all 0.2s ease",

              backdropFilter: `blur(${hoverd ? 4 : 0}px)`,
            }}
          >
            <h4
              style={{
                // color: "#000000",
                fontWeight: 700,
                fontSize: 24,
                marginTop: 20,
                // paddingTop: "15px",
              }}
            >
              {title}
            </h4>

            <Fade in={hoverd} timeout={{ enter: 0, exit: 500 }}>
              <p
                style={{
                  // color: "#000000",
                  fontSize: 16,
                  marginTop: 10,
                }}
              >
                {description}
              </p>
            </Fade>
          </Box>
        </Collapse> 
      */}

      {image && (
        <img
          style={{
            width: "60%",
            // height: "100%",

            borderRadius: "0 0 0 8px",

            zIndex: 10,

            top: 0,
            right: 0,

            position: "absolute",
          }}
          src={image}
          alt={title}
        />
      )}
    </Box>
  );
};

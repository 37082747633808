import { IconButton, InputAdornment, InputBase } from "@mui/material";
import { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

export default function CustomInput(props) {
  const {
    type,
    label,
    required,
    placeholder,
    value,
    setValue,
    multiline,
    rows,
    maxRows,
    style,
    endIcon,
    error,
    disabled,
    pattern,
    onKeyPress,
    sx,
  } = props;

  const { theme } = useContext(ThemeContext);
  // const { register } = useForm();

  const [showText, setShowText] = useState(false);

  return (
    <div style={{ width: "100%", ...style }}>
      {label && (
        <h5
          style={{
            fontWeight: 500,
            paddingBottom: 5,
            color: theme.textColor2,
          }}
        >
          {label}:
          {required && (
            <span style={{ fontWeight: 800, color: "red" }}> *</span>
          )}
        </h5>
      )}
      <InputBase
        onKeyPress={onKeyPress}
        type={
          type === "password"
            ? showText
              ? "text"
              : "password"
            : type || "text"
        }
        sx={{
          //   ml: 1,
          flex: 1,
          border: `1px ${theme.textColor3 + "80"} solid`,
          borderRadius: theme.borderRadius,
          paddingLeft: 10,
          width: "100%",
          minHeight: 40,
          transition: "all .2s ease-in-out",
          bgcolor: error
            ? "rgba(256,0,0,.15)"
            : disabled
            ? theme.textColor3 + "4D"
            : theme.textColor3 + "1A",
          color: theme.textColor,
          "&:fouse": {
            bgcolor: "red",
            borderColor: "red",
          },
          "&:hover": {
            bgcolor: theme.textColor3 + "4D",
            filter: "opacity(85%)",
          },
          ...sx,
        }}
        disabled={disabled}
        focused="true"
        placeholder={placeholder || "Enter text here"}
        inputProps={{ "aria-label": placeholder || "search google maps" }}
        endAdornment={
          endIcon ? (
            <>{endIcon}</>
          ) : (
            type === "password" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowText(!showText)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                  style={{ marginRight: 0 }}
                >
                  {showText ? (
                    <VisibilityRoundedIcon />
                  ) : (
                    <VisibilityOffRoundedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            )
          )
        }
        value={value}
        onChange={(e) => setValue(e.target.value)}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        pattern={pattern}
        // {...register(id)}
        error={true}
      />
      <div style={{ position: "relative" }}>
        <p
          style={{
            position: "absolute",
            color: "rgba(256,0,0,1)",
            fontWeight: "500",
            fontSize: ".7rem",
            top: 3,
          }}
        >
          {error && `Error: ${error}`}
        </p>
      </div>
      {props.children}
    </div>
  );
}

import { Button, IconButton, TextField, useMediaQuery } from "@mui/material";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

import { toSvg } from "html-to-image";

import Header from "../../components/Header";
import "./QRCodeGeneratorPage.css";
import httpClient from "../../libs/httpClient";
import { SnackbarContext } from "../../context/SnackbarContext";
import CustomInput from "../../components/customStyles/CustomInput";
import { useParams } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";

export default function QRCodeGeneratorPage({ disabled }) {
  const { id } = useParams();
  const max600 = useMediaQuery("(max-width:600px)");

  const [URL, setURL] = useState("https://www.privil.link/");

  const [code, setCode] = useState(
    (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase()
  );
  const [loading, setLoading] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    if (id) {
      setLoading(true);
      httpClient()
        .get(`/qrCodes/${id}`)
        .then((res) => {
          if (res.data) {
            setURL(res.data?.url);
            setCode(res.data?.code);
          } else {
            setDataNotFound(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Header />
      {loading ? (
        <LoadingPage fullSize={true} />
      ) : (
        <div className="qrCodeGenPageContainer">
          {dataNotFound ? (
            <div
              className="qrCodeGenPage"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>QR Code Not Found!</p>
            </div>
          ) : (
            <div
              className="qrCodeGenPage"
              style={{
                gap: 20,
                gridTemplateRows: max600 ? "40px 40px auto 1fr" : "40px 1fr",
                gridTemplateColumns: max600 ? "1fr" : "1fr 1fr",
                paddingBottom: max600 ? 20 : 0,
              }}
            >
              <div className="no-print">
                <h1 style={{ textAlign: max600 ? "center" : "left" }}>
                  Static QR Code
                </h1>
              </div>
              <div
                style={{
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                }}
                className="no-print"
              >
                <div />
              </div>

              <div
                className="leftSection no-print"
                style={{ width: max600 ? "100%" : "80%" }}
              >
                {/* <h1>Static QR Code</h1> */}
                <div
                  className="input"
                  style={{
                    display: "grid",
                    gridTemplateColumns: disabled ? "1fr" : "1fr 50px",
                    alignItems: "flex-end",
                    gap: 20,
                    maxWidth: max600 ? "100%" : "80%",
                  }}
                >
                  <CustomInput
                    disabled={disabled}
                    required
                    label="QR Code ID"
                    value={code}
                    setValue={setCode}
                  />

                  <IconButton
                    sx={{ width: 50, height: 50 }}
                    onClick={() => {
                      setCode(
                        (
                          Date.now().toString(36) +
                          Math.random().toString(36).substr(2, 5)
                        ).toUpperCase()
                      );
                    }}
                  >
                    <ReplayRoundedIcon />
                  </IconButton>
                </div>
                {/* <TextField
              disabled={disabled}
              className="input"
              required
              id="outlined-required"
              disabled
              label="Website URL"
              value={URL}
              onChange={(e) => setURL(e.target.value)}
            /> */}
                <CustomInput
                  disabled
                  required
                  label="Website URL"
                  value={URL}
                  setValue={setURL}
                  style={{ maxWidth: max600 ? "100%" : "80%" }}
                />
              </div>
              <RightSection code={code} url={URL} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

const RightSection = ({ code, url }) => {
  const snackbarContext = useContext(SnackbarContext);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleDownloadQRCode = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toSvg(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${code}.svg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    handleSaveQRCode();
  }, [ref]);

  const handlePrintQRCode = () => {
    handleSaveQRCode();
    window.print();
  };

  const handleSaveQRCode = () => {
    setLoading(true);
    httpClient()
      .post("/qrCodes", { url, code })
      .then((res) => {
        snackbarContext.message("QR Code Saved.", "info");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div id="QRCodePro" className="rightSection">
      <div ref={ref}>
        <div
          className="QRCode"
          style={{
            width: 275,
            textAlign: "center",
            paddingBottom: 10,
            // marginTop: 30,
            marginBottom: 20,
          }}
        >
          <h3>{code}</h3>
        </div>

        <div className="QRCode">
          <QRCode ref={ref} value={`${url}${code}`} />
        </div>
      </div>
      {/* <div ref={qrCode} className="QRCode">
    <QRCodeSVG value={`${URL}/${code}`} renderAs="canvas" />
  </div> */}
      <Button className="no-print" onClick={() => handleDownloadQRCode()}>
        Save & Download QR Code
      </Button>
      <Button className="no-print" Button onClick={() => handlePrintQRCode()}>
        Save & Print QR Code
      </Button>

      <canvas id="canvas" style={{ display: "none" }} />
    </div>
  );
};

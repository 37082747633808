import { ButtonBase } from "@mui/material";
import React from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";

export default function QRCodeItem({ data }) {
  return (
    <ButtonBase
      style={{
        backgroundColor: "#FFF",
        aspectRatio: "1/1",
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        borderRadius: 5,
      }}
      component={Link}
      to={`/admin/qrCodes/${data?.code}`}
    >
      <QRCode size={100} value={`${data?.url}${data?.code}`} />

      <div>
        <p style={{ fontSize: ".8rem", fontWeight: 600 }}>ID: {data?.code}</p>
      </div>
    </ButtonBase>
  );
}

import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import SnackbarContextProvider from "./context/SnackbarContext";
import ThemeContextProvider from "./context/ThemeContext";
import RouteController from "./RouteController";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "./libs/stripe";

export default function App() {
  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <ThemeContextProvider>
          <SnackbarContextProvider>
            <AuthContextProvider>
              <RouteController />
            </AuthContextProvider>
          </SnackbarContextProvider>
        </ThemeContextProvider>
      </Elements>
    </BrowserRouter>
  );
}

import React, { useContext } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import ProfileInformationStatus from "./_components/ProfileInformationStatus";
import SubscriptionStatus from "./_components/SubscriptionStatus";

export default function SettingsPage() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <Header />

      <div className="qrCodeGenPageContainer">
        <div
          className="qrCodeGenPage"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <h1 style={{ marginTop: 20 }}>Settings</h1>

          <Box
            sx={{
              display: "grid",
              gap: 16,
              gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
            }}
          >
            <Box>
              <ProfileInformationStatus />
            </Box>

            {["active", "trialing"].includes(user?.subscription?.status) && (
              <SubscriptionStatus />
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

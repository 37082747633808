import { Box, ButtonBase } from "@mui/material";
import React, { useContext } from "react";

import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";

export default function CTASection() {
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        minHeight: 300,
        // height: "100svh",
        height: "max(100%, 100svh)",

        paddingBlock: 100,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        background: "#F5F7F9",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1250,
          height: "100%",

          paddingInline: 20,

          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          gap: 20,
        }}
      >
        <h1
          style={{
            fontSize: bp?.md ? 38 : 42,
            color: theme.textColor,
            fontWeight: 600,
            textAlign: "center",
            lineHeight: 1.35,
          }}
        >
          Empower Your Restaurant with <br />
          Real-Time Feedback
        </h1>

        <a href="/#pricing" style={{ width: "100%", maxWidth: 250 }}>
          <ButtonBase
            sx={{
              marginTop: 20,
              width: "100%",
              maxWidth: 250,

              background:
                theme.name === "dark"
                  ? "#F5F7F9"
                  : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
              color: theme.name === "dark" ? "#131f33" : "#fff",
              borderRadius: 30,
              height: 55,
              fontSize: 16,
              fontWeight: 600,
              "&:hover": {
                background:
                  theme.name === "dark"
                    ? "#F5F7F9"
                    : "linear-gradient(90deg, #213659 0%, #131f33 100%)",
                color: theme.name === "dark" ? "#131f33" : "#fff",

                opacity: 0.85,
              },
            }}
          >
            GET STARTED
          </ButtonBase>
        </a>
      </Box>
    </Box>
  );
}

import "./FeedbackPage.css";
import logo from "../../assets/logo.svg";
import logo_text from "../../assets/logo_text.svg";
import { Rating } from "@mui/material";
import CustomButton from "../../components/customStyles/CustomButton";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import httpClient from "../../libs/httpClient";
import LoadingPage from "../../components/LoadingPage";
import FeedbackSubmitedDialog from "../../components/FeedbackSubmitedDialog";

export default function FeedbackPage() {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [qrCode, setQRCode] = useState();
  const [rate, setRate] = useState(6);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    setLoading(true);
    httpClient()
      .get(`/public/feedback/qrCodes/${id}`)
      .then((res) => {
        console.log("GET FeedbackPage : ", res.data);
        setQRCode(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showFeedbackSubmitedDialog, setShowFeedbackSubmitedDialog] =
    useState(false);

  const handleSubmit = (e) => {
    e && e.preventDefault();

    setLoadingSubmit(true);
    httpClient()
      .post("/public/feedback", { feedback, qrCode: qrCode?._id, rate })
      .then((res) => {
        // alert("DONE");
        setShowFeedbackSubmitedDialog(true);

        setLoadingSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingSubmit(false);
      });
  };

  return loading ? (
    <LoadingPage fullSize isHomePage />
  ) : (
    <div className="feedbackPage">
      <div className="feedbackPageContainer">
        {qrCode ? (
          <div className="feedbackForm">
            <img src={logo} alt="logo art" />

            <div>
              <h3 style={{ color: "#131F33", marginBottom: 0 }}>
                How would you rate our food and service?
              </h3>

              <Rating
                sx={{ paddingTop: "5px" }}
                name="customized-10"
                value={rate}
                // defaultValue={6}
                max={10}
                onChange={(e, value) => {
                  setRate(value);

                  // alert(JSON.stringify(value));
                }}
              />
              {/* <h4 style={{ color: "#213659" }}>
                Please share your thoughts on Food and Service.
              </h4> */}

              <h3 style={{ color: "#131F33" }}>
                Your opinion matters a lot to us! {/* </h3> */}
                {/* <h4 style={{ color: "#213659" }}> */}
                {/* <h3 style={{ color: "#131F33", paddingTop: "0px" }}> */}
                What can we do to improve our food and service?
              </h3>
            </div>

            <textarea
              style={{ backgroundColor: "#F4F6FC", fontSize: 16 }}
              placeholder="type your feedback here..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />

            {/* <Button>SEND</Button> */}
            <CustomButton
              disabled={feedback?.length > 0 ? false : true}
              type="submit"
              label="SEND"
              loading={loadingSubmit}
              onClick={() => handleSubmit()}
            />
          </div>
        ) : (
          <div className="feedbackForm" style={{ maxHeight: 300 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
                flex: 1,
              }}
            >
              <h3
                style={{
                  color: "#131F33",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Oops!
              </h3>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    color: "#213659",
                    fontWeight: 400,
                    paddingTop: "2.5px",

                    fontSize: "0.8rem",
                    lineHeight: "1.5rem",

                    opacity: "0.75",
                    textAlign: "center",
                  }}
                >
                  Fail to get the QR Code.
                  <br /> Please scan again.
                </h4>
              </div>
            </div>
          </div>
        )}

        <img className="LogoText" src={logo_text} alt="logo_text" />
      </div>

      <FeedbackSubmitedDialog
        open={showFeedbackSubmitedDialog}
        setOpen={setShowFeedbackSubmitedDialog}
      />
    </div>
  );
}

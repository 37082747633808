import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { AuthContext } from "../../../context/AuthContext";
import httpClient from "../../../libs/httpClient";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import { SnackbarContext } from "../../../context/SnackbarContext";

import AddPaymentMethodDialog from "../../../dialog/AddPaymentMethodDialog";
import moment from "moment";

export default function SubscriptionStatus() {
  const { message } = useContext(SnackbarContext);
  const { theme } = useContext(ThemeContext);
  const { user, toggleAuth } = useContext(AuthContext);

  const [showAddPaymentMethodDialog, setShowAddPaymentMethodDialog] =
    useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  const getSubscriptionDetails = async () => {
    httpClient()
      .get(`/subscriptions/${user?._id}`)
      .then((res) => {
        console.log(res.data);

        // if (res.data?.error) {
        //   message(res.data?.error, "error");
        //   return;
        // }

        if (!["active", "trialing"].includes(user?.subscription?.status)) {
          toggleAuth();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelSubscription = async () => {
    setLoading(true);

    httpClient()
      .post("/subscriptions/cancel", {
        userId: user?._id,
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);

        toggleAuth();
        message(
          "Subscription canceled requested successfully. Your subscription will be canceled at your next building proud.",
          "warning"
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message(err?.error, "error");
      });
  };

  const upgradeSubscription = async () => {
    setLoading(true);

    httpClient()
      .post("/subscriptions/update", {
        userId: user?._id,
        newPlan: "yearly",
      })
      .then((res) => {
        console.log(res.data);

        if (res.data?.error) {
          message(res.data?.error, "error");
          return;
        }

        toggleAuth();
        message("Subscription upgraded successfully.", "success");
        setLoading(false);
      })
      .catch((err) => {
        console.log("===== > ", err);
        setLoading(false);
        message(err?.error, "error");
      });
  };

  const reactivateSubscription = async () => {
    setLoading(true);

    httpClient()
      .post("/subscriptions/reactivate", {
        userId: user?._id,
      })
      .then((res) => {
        console.log(res.data);

        if (res.data?.error) {
          message(res.data?.error, "error");
          return;
        }

        toggleAuth();
        message("Subscription upgraded successfully.", "success");
        setLoading(false);
      })
      .catch((err) => {
        console.log("===== > ", err);
        setLoading(false);
        message(err?.error, "error");
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          border: `1px solid ${theme.textColor3 + 66}`,
          padding: "12px 16px",
          borderRadius: 8,

          backgroundColor: theme.ui,

          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <h2 style={{ color: theme.textColor2, fontWeight: 600 }}>
          Subscription Status
        </h2>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Box
              sx={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: theme.textColor3 + 33,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WorkspacePremiumRoundedIcon sx={{ fill: "#2E5BFF" }} />
            </Box>

            <h4 style={{ color: theme.textColor2, fontWeight: 500 }}>
              {capitalizeFirstLetter(user?.subscription?.planName)} Plan
            </h4>
          </Box>

          {/*  */}
          <h4
            style={{
              color: theme.textColor2,
              fontWeight: 500,
              color: "#2E5BFF",

              backgroundColor: "#2E5BFF33",
              padding: "4px 16px",
              borderRadius: 16,
            }}
          >
            {user?.subscription?.status === "trialing"
              ? "Free service for 3 months"
              : capitalizeFirstLetter(user?.subscription?.status)}
          </h4>
        </Box>

        {/*  */}
        <Box>
          <p
            style={{
              color: theme.textColor2,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            Your current plan includes access to all premium features, 24/7
            customer support, and regular updates.
          </p>
        </Box>

        {/*  */}

        <Box
          sx={{
            display: "flex",
            gap: 16,

            marginTop: 16,
            marginBottom: 8,
          }}
        >
          {!user?.subscription?.cancelAtPeriodEnd && (
            <Button
              disabled={user?.subscription?.cancelAtPeriodEnd}
              sx={{
                color: "#000000",
                backgroundColor: "#00000011",
                padding: "6px 16px",

                textTransform: "none",
                fontWeight: 500,
                borderRadius: 6,
                border: `1px solid #00000033`,
                "&:disabled": {
                  backgroundColor: "#00000044",
                  color: "#00000099",
                  cursor: "not-allowed",
                },
              }}
              onClick={() => cancelSubscription()}
            >
              Cancel Subscription
            </Button>
          )}

          {user?.subscription?.cancelAtPeriodEnd && (
            <Button
              disabled={!user?.subscription?.cancelAtPeriodEnd}
              sx={{
                color: "#000000",
                backgroundColor: "#00000011",
                padding: "6px 16px",

                textTransform: "none",
                fontWeight: 500,
                borderRadius: 6,
                border: `1px solid #00000033`,
                "&:disabled": {
                  backgroundColor: "#00000044",
                  color: "#00000099",
                  cursor: "not-allowed",
                },
              }}
              onClick={() => reactivateSubscription()}
            >
              Reactivate
            </Button>
          )}

          {/* {user?.subscription?.name === "monthly" && ( */}
          <Button
            title={
              user?.subscription?.planName === "yearly"
                ? "You are already on yearly plan"
                : "Upgrade to yearly plan"
            }
            disabled={user?.subscription?.planName === "yearly"}
            sx={{
              color: "#ffffff",
              backgroundColor: "#000000",
              padding: "6px 16px",

              textTransform: "none",
              fontWeight: 500,
              borderRadius: 6,

              "&:hover": {
                backgroundColor: "#000000cc",
              },
              "&:disabled": {
                backgroundColor: "#00000044",
                color: "#00000099",
                cursor: "not-allowed",
              },
            }}
            onClick={() => upgradeSubscription()}
          >
            Upgrade Plan
          </Button>
          {/* // )} */}

          {/* <Button
            sx={{
              color: "#ffffff",
              backgroundColor: "#000000",
              padding: "6px 16px",

              textTransform: "none",
              fontWeight: 500,
              borderRadius: 6,

              "&:hover": {
                backgroundColor: "#000000cc",
              },
            }}
            onClick={() => setShowAddPaymentMethodDialog(true)}
          >
            Add Payment Method
          </Button> */}
        </Box>

        {user?.subscription?.cancelAtPeriodEnd && (
          <p style={{ color: "red", fontSize: 12 }}>
            Note: Your subscription will be canceled at the end of your current
            billing period aka "
            {moment(user?.subscription?.currentPeriodEnd).calendar()}". You can
            continue to use the premium features until then.
          </p>
        )}
        {/* <p style={{ color: "red", fontSize: 12 }}>
          Note: You will be charged $99 at the end of your current billing
          period.
        </p> */}
      </Box>

      <AddPaymentMethodDialog
        open={showAddPaymentMethodDialog}
        setOpen={setShowAddPaymentMethodDialog}
      />
    </>
  );
}

const capitalizeFirstLetter = (inputString = "") => {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

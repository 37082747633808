import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";
import ConfirmationPage from "./pages/auth/ConfirmationPage";
import SignupPage from "./pages/auth/SignupPage";
import { AuthContext } from "./context/AuthContext";

import HomePage from "./pages/homePage/HomePage";
import FeedbackPage from "./pages/feedbackPage/FeedbackPage";
import QRCodesPage from "./pages/admin/qrCodesPage/QRCodesPage";
import QRCodeGeneratorPage from "./pages/qrCodeGeneratorPage/QRCodeGeneratorPage";
import LoadingPage from "./components/LoadingPage";
import QRCodePage from "./pages/admin/QRCodePage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyServicePage from "./pages/PrivacyServicePage";
import SubscriptionWarningPage from "./pages/subscription/SubscriptionWarningPage";
import SubscriptionSuccessPage from "./pages/subscription/SubscriptionSuccessPage";
import SettingsPage from "./pages/settingsPage/SettingsPage";
import DashboardPage from "./pages/DashboardPage";
import AdminPage from "./pages/admin/AdminPage";
import AdminContactPage from "./pages/admin/AdminContactPage";
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import { Elements } from "@stripe/react-stripe-js";
import stripePromise from "./libs/stripe";

export default function RouteController() {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated === null ? (
    <LoadingPage fullSize={true} />
  ) : (
    <Routes>
      <Route
        path="/"
        // element={isAuthenticated ? <Navigate to="/admin" /> : <HomePage />}
        element={<HomePage />}
      />

      <Route path="/:id" element={<FeedbackPage />} />

      <Route
        path="/admin"
        element={
          <RequireAuth checkAdmin>
            <AdminPage />
          </RequireAuth>
        }
      />

      <Route
        path="/admin/qrCodes"
        element={
          <RequireAuth checkAdmin>
            <QRCodesPage />
          </RequireAuth>
        }
      />

      <Route
        path="/admin/qrCodes/generate"
        element={
          <RequireAuth checkAdmin>
            <QRCodeGeneratorPage />
          </RequireAuth>
        }
      />
      <Route
        path="/admin/qrCodes/:id"
        element={
          <RequireAuth checkAdmin>
            <QRCodePage disabled={true} />
          </RequireAuth>
        }
      />

      <Route
        path="/admin/contact"
        element={
          <RequireAuth>
            <AdminContactPage />
          </RequireAuth>
        }
      />

      <Route
        path="/admin/users"
        element={
          <RequireAuth checkAdmin>
            <AdminUsersPage />
          </RequireAuth>
        }
      />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        }
      />

      <Route
        path="/settings"
        element={
          <RequireAuth checkStatus={false}>
            <SettingsPage />
          </RequireAuth>
        }
      />

      <Route
        path="/forgot-password"
        element={
          !isAuthenticated ? <ForgotPasswordPage /> : <Navigate to="/" />
        }
      />
      <Route
        path="/reset-password/:confirmationCode"
        element={!isAuthenticated ? <ResetPasswordPage /> : <Navigate to="/" />}
      />

      <Route
        path="/login"
        element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" />}
      />
      <Route
        path="/login/:confirmationCode"
        element={!isAuthenticated ? <ConfirmationPage /> : <Navigate to="/" />}
      />
      {/* <Route path="/signup" element={<Navigate to="/" />} /> */}
      <Route
        path="/admin/signup"
        element={!isAuthenticated ? <SignupPage /> : <Navigate to="/" />}
      />
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <Route path="/tos" element={<TermsOfServicePage />} />
      <Route path="/privacyService" element={<PrivacyServicePage />} />

      {/* <Route path="/subscription" element={<SubscriptionStatusPage />} /> */}

      <Route
        path="/subscription/success"
        element={
          <RequireAuth>
            <SubscriptionSuccessPage />
          </RequireAuth>
        }
      />
      <Route
        path="/subscription/warning"
        element={
          <RequireAuth checkStatus={false}>
            <SubscriptionWarningPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

const RequireAuth = ({ children, checkStatus = true, checkAdmin }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  console.log(user);

  if (isAuthenticated === null) return <LoadingPage fullSize={true} />;

  if (isAuthenticated === false) return <Navigate to="/login" />;

  if (user.role === "admin") return children;
  else if (
    checkStatus &&
    !["active", "trialing"].includes(user?.subscription?.status) &&
    !user.isFreebie
  )
    return <Navigate to="/subscription/warning" />;
  else return children;
};
